<template>
    <client-page>
        <template #contentsHead>
            <sub-head-primary bg="/images/sub/sub-visual.jpg">
                <template #tit>공지사항</template>
                <div class="sub-tab-wrap">
                    <v-container>
                        <u-tabs-filled>
                            <u-tab-filled to="/center/notice">공지사항</u-tab-filled>
                            <u-tab-filled to="/center/inquiry">문의하기</u-tab-filled>
                            <u-tab-filled to="/center/faq">자주묻는 질문</u-tab-filled>
                        </u-tabs-filled>
                    </v-container>
                </div>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
        </page-section-primary>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    components: {
        ClientPage,
        SubHeadPrimary,
        UTabsFilled,
        UTabFilled,
        PageSectionPrimary,

        NotificationList,
        NotificationView,
    },
};
</script>
, PageSectionPrimary
