<template>
    <client-page>
        <template #contentsHead>
            <sub-head-primary bg="/images/sub/sub-visual.jpg">
                <template #tit>문의하기</template>
                <div class="sub-tab-wrap">
                    <v-container>
                        <u-tabs-filled>
                            <u-tab-filled to="/center/notice">공지사항</u-tab-filled>
                            <u-tab-filled to="/center/inquiry">문의하기</u-tab-filled>
                            <u-tab-filled to="/center/faq">자주묻는 질문</u-tab-filled>
                        </u-tabs-filled>
                    </v-container>
                </div>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <!-- <board-question-list v-if="$route.query._question === undefined" v-bind="{ code }" /> -->
            <board-question-input2 />
            <!-- <board-question-view v-else-if="$route.query._question" v-bind="{ code }" /> -->
        </page-section-primary>
    </client-page>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

import BoardQuestionList from "@/components/client/board/skin/board-question/list.vue";
import BoardQuestionInput2 from "@/components/client/board/skin/board-question/input2.vue";
import BoardQuestionView from "@/components/client/board/skin/board-question/view.vue";

export default {
    components: {
        ClientPage,
        SubHeadPrimary,
        UTabsFilled,
        UTabFilled,
        PageSectionPrimary,

        BoardQuestionList,
        BoardQuestionInput2,
        BoardQuestionView,
    },
    props: {
        code: { type: String, default: USER_TYPES.CONSUMER.value },
    },
};
</script>
