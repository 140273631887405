var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section-primary', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-container', {
          staticClass: "container--xxs"
        }, [_c('div', {
          staticClass: "pb-16px pb-md-20px pb-lg-24px"
        }, [_c('div', [_vm._v("수신자 등록 엑셀 업로드 방법")]), _c('div', [_vm._v("1) 양식 다운로드 버튼을 눌러 양식을 다운받습니다.")]), _c('div', [_vm._v("2) 핸드폰 번호 이름 수량을 두번째 줄부터 입력합니다 (1.000명까지 입력 가능합니다).")]), _c('div', [_vm._v("3) 파일을 저장 후 엑셀로 업로드 클릭")]), _c('div', [_vm._v("4) 입력된 연락처와 수량을 최종 확인 후 다음으로 이동")])]), _c('div', {
          staticClass: "pb-16px pb-md-20px pb-lg-24px"
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-small",
          on: {
            "click": _vm.sampleDownload
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary2), false), [_vm._v("양식 다운로드 "), _c('u-icon', {
          staticClass: "ml-4px ml-md-8px"
        }, [_vm._v("file_download")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-small",
          on: {
            "click": function ($event) {
              return _vm.$refs.uploader.$refs.input.click();
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("엑셀로 업로드 "), _c('u-icon', {
          staticClass: "ml-4px ml-md-8px"
        }, [_vm._v("file_upload")])], 1), _c('v-file-input', {
          ref: "uploader",
          staticClass: "d-none",
          on: {
            "change": function (file) {
              return _vm.upload(file);
            }
          }
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium pr-4px pr-md-8px"
        }, [_vm._v("등록된 수신자")]), _c('b', {
          staticClass: "font-size-18 font-size-md-20"
        }, [_c('span', {
          staticClass: "red--text text--lighten-1"
        }, [_vm._v(_vm._s(_vm.display))]), _vm._v("/" + _vm._s(_vm.quantity))])])], 1)], 1), _c('v-data-table', _vm._b({
          attrs: {
            "headers": _vm.headers,
            "items": _vm.excelItems.slice((_vm.page - 1) * _vm.limit, _vm.limit * _vm.page),
            "no-data-text": "내역이 없습니다."
          },
          scopedSlots: _vm._u([{
            key: `item.no`,
            fn: function (_ref) {
              var item = _ref.item;
              return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + _vm.excelItems.slice((_vm.page - 1) * _vm.limit, _vm.limit * _vm.page).indexOf(item) + 1) + " ")];
            }
          }, {
            key: `item.name`,
            fn: function (_ref2) {
              var item = _ref2.item,
                index = _ref2.index;
              return [_c('v-text-field', _vm._b({
                attrs: {
                  "placeholder": index === 0 ? '홍길동' : ''
                },
                model: {
                  value: item.name,
                  callback: function ($$v) {
                    _vm.$set(item, "name", $$v);
                  },
                  expression: "item.name"
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            }
          }, {
            key: `item.phone`,
            fn: function (_ref3) {
              var item = _ref3.item,
                index = _ref3.index;
              return [_c('v-text-field', _vm._b({
                attrs: {
                  "placeholder": index === 0 ? '010-1234-5678' : ''
                },
                on: {
                  "input": function ($event) {
                    return _vm.formatPhone(index);
                  }
                },
                model: {
                  value: item.phone,
                  callback: function ($$v) {
                    _vm.$set(item, "phone", $$v);
                  },
                  expression: "item.phone"
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            }
          }, {
            key: `item.quantity`,
            fn: function (_ref4) {
              var item = _ref4.item,
                index = _ref4.index;
              return [_c('v-text-field', _vm._b({
                attrs: {
                  "type": "number",
                  "placeholder": index === 0 ? '1' : '0'
                },
                on: {
                  "input": function ($event) {
                    return _vm.formatQuantity(index);
                  }
                },
                model: {
                  value: item.quantity,
                  callback: function ($$v) {
                    _vm.$set(item, "quantity", $$v);
                  },
                  expression: "item.quantity"
                }
              }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))];
            }
          }, {
            key: `item.actions`,
            fn: function (_ref5) {
              var item = _ref5.item,
                index = _ref5.index;
              return [item.name && item.phone && item.quantity ? _c('v-btn', {
                staticClass: "v-btn--text-fit rounded-circle pa-0",
                attrs: {
                  "large": "",
                  "icon": "",
                  "text": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteExcelItem(index);
                  }
                }
              }, [_c('u-icon', {
                attrs: {
                  "variant": "",
                  "color": "grey--text text--lighten-1"
                }
              }, [_vm._v("cancel")])], 1) : _vm._e()];
            }
          }], null, true)
        }, 'v-data-table', Object.assign({}, _vm.table_primary), false)), _c('div', {
          staticClass: "pagination-wrap pagination-wrap--sm"
        }, [_c('v-pagination', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('prev', 1);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("이전으로 이동")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": _vm.goStep
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("다음으로 이동")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }