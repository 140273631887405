<template>
    <client-page>
        <page-section-primary class="page-section--first page-section--last">
            <template #tit>서비스 소개</template>

            <v-img src="/images/sub/about/about-img.jpg" />
            <router-link to="/buying">
                <v-img src="/images/sub/about/about-img2.jpg" />
            </router-link>
            <v-img src="/images/sub/about/about-img3.jpg" />
        </page-section-primary>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

export default {
    components: {
        ClientPage,
        PageSectionPrimary,
    },
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>

<style lang="scss" scoped></style>
