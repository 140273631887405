var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "bg": "/images/sub/sub-visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("공지사항")];
            },
            proxy: true
          }])
        }, [_c('div', {
          staticClass: "sub-tab-wrap"
        }, [_c('v-container', [_c('u-tabs-filled', [_c('u-tab-filled', {
          attrs: {
            "to": "/center/notice"
          }
        }, [_vm._v("공지사항")]), _c('u-tab-filled', {
          attrs: {
            "to": "/center/inquiry"
          }
        }, [_vm._v("문의하기")]), _c('u-tab-filled', {
          attrs: {
            "to": "/center/faq"
          }
        }, [_vm._v("자주묻는 질문")])], 1)], 1)], 1)])];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [!_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }