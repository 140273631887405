<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 46 46" class="icon-svg icon-order-completed">
        <g>
            <path
                d="M42.4,25.4c0.2-0.4,0.1-0.9-0.3-1.1c-4.6-2.4-10.2-1.8-14.2,1.4l-1.3-1.3l2.2-2.2c0.3-0.3,0.3-0.8,0-1.2
		c-0.3-0.3-0.8-0.3-1.2,0l-2.2,2.2l-5.3-5.3c1.2-1.6,2-3.5,2.2-5.5c0.3-2.3-0.2-4.6-1.3-6.7c-0.2-0.4-0.7-0.5-1.1-0.3
		c-0.4,0.2-0.5,0.7-0.3,1.1c1.8,3.3,1.5,7.3-0.7,10.3l-3.5-3.5c-0.5-0.5-1.2-0.7-1.9-0.5c-0.7,0.1-1.2,0.6-1.5,1.3l-10,27.3
		C1.9,42,2,42.8,2.6,43.4C3,43.8,3.5,44,4,44l0,0c0.2,0,0.5-0.1,0.7-0.1l27.3-10c0.7-0.2,1.1-0.8,1.3-1.5c0.2-0.7,0-1.4-0.5-1.9
		L29,26.9c3.5-2.8,8.3-3.2,12.3-1.1C41.7,26,42.2,25.8,42.4,25.4z M15.1,38.3l-7.4-7.4l2-5.6l10.9,10.9L15.1,38.3z M11.1,39.8
		l-4.9-4.9l0.8-2.3l6.3,6.3L11.1,39.8z M4.2,42.3c-0.2,0.1-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.1-0.4l1.9-5.2l3.8,3.8L4.2,42.3z
		 M31.7,32.1c0,0.1-0.1,0.2-0.2,0.3l-5,1.8l-4.7-4.7c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l4.1,4.1l-2.3,0.8l-12-12
		l0.8-2.3l4.1,4.1c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.2l-4.7-4.7l1.8-5c0.1-0.2,0.2-0.2,0.3-0.2
		c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1L24.8,25l0,0l0,0l6.8,6.8C31.7,31.9,31.7,32,31.7,32.1z" />
            <path
                d="M26.7,8.6c0.5,0,0.8-0.4,0.8-0.8c0-0.7,0.6-1.2,1.2-1.2c1.6,0,2.9-1.3,2.9-2.9c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8
		c0,0.7-0.6,1.2-1.2,1.2c-1.6,0-2.9,1.3-2.9,2.9C25.9,8.2,26.3,8.6,26.7,8.6z" />
            <path
                d="M34,16.3c1.6,0,3-1.3,3-3c0-0.7,0.6-1.3,1.3-1.3c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8c-1.6,0-3,1.3-3,3
		c0,0.7-0.6,1.3-1.3,1.3c-0.5,0-0.8,0.4-0.8,0.8S33.6,16.3,34,16.3z" />
            <path
                d="M41.4,15.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C44,16.4,42.8,15.2,41.4,15.2z M41.4,18.9
		c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1s1,0.4,1,1C42.4,18.4,41.9,18.9,41.4,18.9z" />
            <path
                d="M27.9,15.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6C25.2,14.7,26.4,15.9,27.9,15.9z
		 M27.9,12.3c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C26.9,12.8,27.3,12.3,27.9,12.3z" />
            <path
                d="M12.5,7.3L12.5,7.3c1.5,0,2.6-1.2,2.6-2.6S14,2,12.5,2S9.9,3.2,9.9,4.6S11.1,7.3,12.5,7.3z M12.5,3.6c0.5,0,1,0.4,1,1
		s-0.4,1-1,1s-1-0.4-1-1S12,3.6,12.5,3.6z" />
            <path
                d="M39.1,30.8c-0.3-0.3-0.8-0.3-1.2,0s-0.3,0.8,0,1.2l0.8,0.8c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.2
		L39.1,30.8z" />
            <path
                d="M43,34.7c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l0.8,0.8c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
		c0.3-0.3,0.3-0.8,0-1.2L43,34.7z" />
            <path
                d="M42.6,30.8l-0.8,0.8c-0.3,0.3-0.3,0.8,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l0.8-0.8c0.3-0.3,0.3-0.8,0-1.2
		S42.9,30.5,42.6,30.8z" />
            <path
                d="M38.7,34.7L38,35.4c-0.3,0.3-0.3,0.8,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l0.8-0.8c0.3-0.3,0.3-0.8,0-1.2
		C39.6,34.4,39.1,34.4,38.7,34.7z" />
            <path
                d="M42.4,4.4c0.2,0,0.4-0.1,0.6-0.2l0.8-0.8c0.3-0.3,0.3-0.8,0-1.2s-0.8-0.3-1.2,0L41.8,3c-0.3,0.3-0.3,0.8,0,1.2
		C42,4.3,42.2,4.4,42.4,4.4z" />
            <path
                d="M38.5,8.3c0.2,0,0.4-0.1,0.6-0.2l0.8-0.8c0.3-0.3,0.3-0.8,0-1.2s-0.8-0.3-1.2,0L38,6.9c-0.3,0.3-0.3,0.8,0,1.2
		C38.1,8.2,38.3,8.3,38.5,8.3z" />
            <path
                d="M43,6.1c-0.3-0.3-0.8-0.3-1.2,0s-0.3,0.8,0,1.2L42.6,8c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
		c0.3-0.3,0.3-0.8,0-1.2L43,6.1z" />
            <path
                d="M38.7,4.2c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.2l-0.8-0.8c-0.3-0.3-0.8-0.3-1.2,0s-0.3,0.8,0,1.2
		L38.7,4.2z" />
            <path
                d="M19.3,27.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1
		c0,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0
		c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
		c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0
		c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1C19.3,27.3,19.3,27.2,19.3,27.2z" />
            <path
                d="M30.5,18.1l-0.6,0.6c-0.3,0.3-0.3,0.8,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l0.6-0.6c0.3-0.3,0.3-0.8,0-1.2
		C31.3,17.8,30.8,17.8,30.5,18.1z" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-order-completed {
    width: 46px;
}
</style>
