var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-template",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 46 46"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M22.4,23.6l-0.7,6.5c0,0.3,0.1,0.6,0.4,0.8c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5-0.2l5.3-3.9c0.1-0.1,0.2-0.2,0.2-0.2\n\t\tL38.8,8.8c1-1.6,0.4-3.8-1.3-4.7l0,0c-0.8-0.5-1.7-0.6-2.6-0.3C34,4,33.3,4.6,32.8,5.4L22.5,23.3C22.4,23.4,22.4,23.5,22.4,23.6z\n\t\t M34.2,6.2c0.2-0.4,0.6-0.7,1.1-0.8c0.5-0.1,1-0.1,1.4,0.2s0.7,0.6,0.8,1.1c0.1,0.5,0.1,1-0.2,1.4v0l-0.8,1.4l-3.1-1.8L34.2,6.2z\n\t\t M32.6,9l3.1,1.8l-8.3,14.3l-3.1-1.8L32.6,9z M23.9,25l2.4,1.4l-2.8,2L23.9,25z"
    }
  }), _c('path', {
    attrs: {
      "d": "M38.2,14.5c-0.5,0-0.8,0.4-0.8,0.8v3.2c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-3.2C39,14.8,38.6,14.5,38.2,14.5z"
    }
  }), _c('path', {
    attrs: {
      "d": "M38.9,21.8c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1\n\t\tc0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1\n\t\tc0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1\n\t\tc0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0\n\t\tc0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1\n\t\tc0,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1\n\t\tc0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1C39,21.9,38.9,21.9,38.9,21.8z"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.5,25.2h7c0.6,0,1-0.4,1-1c0-0.5-0.5-1-1-1h-7c-0.5,0-1,0.4-1,1S11,25.2,11.5,25.2z"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.5,20.6h7c0.6,0,1-0.4,1-1c0-0.5-0.5-0.9-1-0.9h-7c-0.5,0-1,0.4-1,0.9C10.6,20.2,11,20.6,11.5,20.6z"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.5,29.7h7c0.6,0,1-0.4,1-0.9c0-0.5-0.5-1-1-1h-7c-0.5,0-1,0.4-1,1C10.6,29.3,11,29.7,11.5,29.7z"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.5,34.3h14.9c0.5,0,1-0.4,1-1c0-0.5-0.4-0.9-1-0.9H11.5c-0.5,0-1,0.4-1,0.9C10.6,33.9,11,34.3,11.5,34.3z"
    }
  }), _c('path', {
    attrs: {
      "d": "M38.1,25.1c-0.5,0-0.8,0.4-0.8,0.8v4.6h0v9.7h0c0,0.7-0.6,1.3-1.3,1.3H14.6c-0.7,0-1.3-0.6-1.3-1.3v-1.4h17.2v0\n\t\tc1.6,0,3-1.3,3-3V25.5c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v10.4c0,0.7-0.6,1.3-1.3,1.3H9c-0.7,0-1.3-0.6-1.3-1.3V6\n\t\tc0-0.7,0.6-1.3,1.3-1.3h20.8c0.4,0,0.8-0.4,0.8-0.8S30.2,3,29.8,3H9C7.3,3,6,4.3,6,6v29.8c0,1.7,1.3,3,3,3h2.6v1.4c0,1.6,1.3,3,3,3\n\t\tH36c1.7,0,3-1.3,3-3v-9.7v0v-4.6C39,25.4,38.6,25.1,38.1,25.1z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }