<template>
	<v-card v-bind="{ ...card_primary }">
		<v-stepper-header>
			<v-stepper-step :complete="value >= 1" step="1">
				<div class="v-stepper__icon">
					<icon-order-details />
				</div>
				<tit-primary titSize="tit--xs" class="grey--text">STEP 1</tit-primary>
				<txt-primary txtSize="txt--sm" class="grey--text text--lighten-1 mt-2px">주문정보</txt-primary>
				<div class="v-stepper__arrow">
					<icon-arrow-rounded direction="right" color="grey--text text--lighten-3" />
				</div>
			</v-stepper-step>
			<v-stepper-step :complete="value >= 2" step="2">
				<div class="v-stepper__icon">
					<icon-reception />
				</div>
				<tit-primary titSize="tit--xs" class="grey--text">STEP 2</tit-primary>
				<txt-primary txtSize="txt--sm" class="grey--text text--lighten-1 mt-2px">수신자 등록</txt-primary>
				<div class="v-stepper__arrow">
					<icon-arrow-rounded direction="right" color="grey--text text--lighten-3" />
				</div>
			</v-stepper-step>
			<v-stepper-step :complete="value >= 3" step="3">
				<div class="v-stepper__icon">
					<icon-template />
				</div>
				<tit-primary titSize="tit--xs" class="grey--text">STEP 3</tit-primary>
				<txt-primary txtSize="txt--sm" class="grey--text text--lighten-1 mt-2px">템플릿 등록</txt-primary>
				<div class="v-stepper__arrow">
					<icon-arrow-rounded direction="right" color="grey--text text--lighten-3" />
				</div>
			</v-stepper-step>
			<v-stepper-step :complete="value >= 4" step="4">
				<div class="v-stepper__icon">
					<icon-order-completed />
				</div>
				<tit-primary titSize="tit--xs" class="grey--text">STEP 4</tit-primary>
				<txt-primary txtSize="txt--sm" class="grey--text text--lighten-1 mt-2px">주문완료</txt-primary>
			</v-stepper-step>
		</v-stepper-header>
	</v-card>
</template>

<script>
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import IconTemplate from "@/components/publish/styles/icons/icon-template.vue";
import IconReception from "@/components/publish/styles/icons/icon-reception.vue";
import IconOrderDetails from "@/components/publish/styles/icons/icon-order-details.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";
import IconOrderCompleted from "@/components/publish/styles/icons/icon-order-completed.vue";

export default {
	components: {
		TitPrimary,
		TxtPrimary,
		IconTemplate,
		IconReception,
		IconOrderDetails,
		IconArrowRounded,
		IconOrderCompleted
	},
	props: {
		value: { type: Number, default: 0 },
		card_primary: { type: Object, default: () => ({}) },
	}
}
</script>

<style>

</style>