var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section-primary', {
    ref: "stepperItems",
    staticClass: "page-section--first page-section--last"
  }, [_vm.ready ? _c('v-stepper', {
    attrs: {
      "alt-labels": "",
      "non-linear": ""
    },
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('stepper-header', _vm._b({
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, 'stepper-header', {
    card_primary: _vm.card_primary
  }, false)), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('buyingOrderStep1', _vm._g(_vm._b({
    ref: "step1",
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'buyingOrderStep1', {
    attrs_input: _vm.attrs_input,
    btn_primary: _vm.btn_primary,
    attrs_select_common: _vm.attrs_select_common,
    attrs_controls_common: _vm.attrs_controls_common
  }, false), {
    go: _vm.go
  }))], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_c('buyingOrderStep2', _vm._g(_vm._b({
    ref: "step2",
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'buyingOrderStep2', {
    attrs_input: _vm.attrs_input,
    btn_primary: _vm.btn_primary,
    btn_primary2: _vm.btn_primary2,
    table_primary: _vm.table_primary,
    btn_secondary: _vm.btn_secondary,
    excelItems: _vm.excelItems,
    display: _vm.display,
    quantity: _vm.quantity
  }, false), {
    go: _vm.go,
    prev: _vm.prev
  }))], 1), _c('v-stepper-content', {
    attrs: {
      "step": "3"
    }
  }, [_c('buyingOrderStep3', _vm._g(_vm._b({
    ref: "step3",
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'buyingOrderStep3', {
    attrs_input: _vm.attrs_input,
    btn_primary: _vm.btn_primary,
    card_primary: _vm.card_primary,
    btn_primary3: _vm.btn_primary3,
    btn_secondary: _vm.btn_secondary,
    card_secondary: _vm.card_secondary,
    attrs_controls_common: _vm.attrs_controls_common,
    templates: _vm.templates,
    page: _vm.page,
    pageCount: _vm.pageCount,
    company: _vm.company,
    carts: _vm.carts
  }, false), {
    go: _vm.go,
    prev: _vm.prev,
    getTemplate: _vm.getTemplate,
    setTemplate: _vm.setTemplate
  }))], 1), _c('v-stepper-content', {
    attrs: {
      "step": "4"
    }
  }, [_c('buyingOrderStep4', _vm._g(_vm._b({
    model: {
      value: _vm.carts[0],
      callback: function ($$v) {
        _vm.$set(_vm.carts, 0, $$v);
      },
      expression: "carts[0]"
    }
  }, 'buyingOrderStep4', {
    card_primary: _vm.card_primary,
    btn_primary: _vm.btn_primary,
    totalAmount: _vm.totalAmount,
    table_primary: _vm.table_primary,
    excelItems: _vm.displayExcelItems
  }, false), {
    pay: _vm.pay
  }))], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }