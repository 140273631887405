<template>
    <div>
        <!-- <div class="tabs-wrap">
            <v-tabs grow color="grey darken-4">
                <v-tab :class="{ on: filter.type == '회원서비스' }" @click="((filter.type = '회원서비스') || true) && search()">회원서비스</v-tab>
                <v-tab :class="{ on: filter.type == '상품' }" @click="((filter.type = '상품') || true) && search()">상품</v-tab>
                <v-tab :class="{ on: filter.type == '배송' }" @click="((filter.type = '배송') || true) && search()">배송</v-tab>
                <v-tab :class="{ on: filter.type == '교환/반품' }" @click="((filter.type = '교환/반품') || true) && search()">교환/반품</v-tab>
                <v-tab :class="{ on: filter.type == '입금/결제' }" @click="((filter.type = '입금/결제') || true) && search()">입금/결제</v-tab>
                <v-tab :class="{ on: filter.type == '기타' }" @click="((filter.type = '기타') || true) && search()">기타</v-tab>
            </v-tabs>
        </div> -->

        <!-- 개발확인부탁드립니다 -->
        <!-- S: 검색 -->
        <board-filter
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        />
        <!-- E: 검색 -->

        <!-- S: 게시판 -->
        <v-divider style="border-width: 2px 0 0" class="grey darken-4" />
        <v-expansion-panels flat>
            <v-expansion-panel v-for="faq in faqs" :key="faq">
                <v-expansion-panel-header class="pa-12px px-md-16px border-b">
                    <v-row align="center" class="row--xs">
                        <v-col cols="12" md="auto">
                            <div class="min-w-70px">
                                <!-- 개발확인부탁드립니다 -->
                                <v-chip x-small outlined>회원문의</v-chip>
                            </div>
                        </v-col>
                        <v-col cols="12" md="">
                            <v-row align="center" class="row--xs">
                                <v-col cols="12" md="auto">
                                    <txt-primary txtSize="txt--lg" class="txt--dark font-weight-bold">Q</txt-primary>
                                </v-col>
                                <v-col cols="12" md="">
                                    <txt-primary txtSize="txt--sm" class="txt--dark font-weight-medium">{{ faq.subject }}</txt-primary>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="border-b">
                    <div class="grey lighten-5 pa-12px px-md-16px pl-lg-100px pr-lg-48px">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" md="auto">
                                <txt-primary txtSize="txt--lg" class="font-weight-bold grey--text text--lighten-1">A</txt-primary>
                            </v-col>
                            <v-col cols="12" md="">
                                <txt-primary txtSize="txt--sm">
                                    <span class="break-words" v-html="faq.content.replace(/\n/g, '<br>')" />
                                </txt-primary>
                            </v-col>
                        </v-row>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-fade-transition leave-absolute>
            <v-sheet v-show="!faqs?.length" class="text-center">
                <div class="txt txt--sm text-center py-20px py-md-30px">등록된 질문이 없습니다</div>
                <v-divider />
            </v-sheet>
        </v-fade-transition>
        <!-- E: 게시판 -->

        <!-- S: 페이징 -->
        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import UTitWrapLine from "@/components/publish/styles/typography/u-tit-wrap-line.vue";
import BoardFilter from "@/components/client/board/board-filter.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    components: {
        UTitWrapLine,
        BoardFilter,
        TxtPrimary,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            filter: {
                type: "회원서비스",
            },
            faqs: [],
            summary: null
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search() {
            try {
                var { summary, faqs } = await api.v1.center.faqs.getFaqs({
                    params: this.filter,
                });

                this.faqs = faqs;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-expansion-panel {
        &-header {
            min-height: 54px;
        }
        &--active {
            > .v-expansion-panel-header {
                min-height: 54px;
            }
        }
    }
}
</style>
