<template>
	<client-page>
		<v-layout fill-height class="member-login">
			<v-responsive height="auto" min-height="55vh">
				<div class="background-layout">
					<v-img src="/images/ucardkorea/login-bg.png" max-width="100%" height="100%" />
				</div>
				
				<v-layout justify-center align-center fill-height class="px-4 py-8">
					<v-flex>
						<v-card width="100%" max-width="480" min-height="220" rounded="lg" class="ma-auto">
							<v-card-title class="title justify-center py-6">충전하기</v-card-title>
							<v-tabs-items v-model="tabIndex" class="transparent align-celf-center">
								<v-tab-item :key="0">
									<v-divider/>
									<v-card-text>
										<div class="text-center">나누리 상품권은 대한문고 캐시로 자동충전이 가능합니다.</div>
										<v-btn width="100%" x-large elevation="0" :color="colors.daehanbooks" outlined class="mt-4" @click="place = 'daehanbooks'; tabIndex = 1">
											<img class="mr-2" />
											<span>대한문고 캐시전환</span>
										</v-btn>
									</v-card-text>
								</v-tab-item>
								
								<v-tab-item :key="1">
									<v-divider/>
									
									<!-- 옴니스 포인트 충전 -->
									<template v-if="place == 'daehanbooks'">
										<v-card-text class="pa-8">
											<div><b>대한문고 계정으로 로그인</b></div>
											<v-text-field v-model="username" label="아이디" persistent-placeholder hide-details class="mt-4"/>
											<v-password-field v-model="password" label="비밀번호" persistent-placeholder hide-details class="mt-4" />
											<v-btn width="100%" x-large dark class="mt-4" @click="login">로그인</v-btn>
										</v-card-text>
									</template>

								</v-tab-item>
								<v-tab-item>
										<v-divider/>
										<v-data-table :items="pins" :headers="pinsHeaders"/>
										<v-divider/>
										<v-card-text class="pa-8">
											<v-btn width="100%" x-large dark @click="save" >충전하기</v-btn>
										</v-card-text>
								</v-tab-item>
							</v-tabs-items>
						</v-card>
					</v-flex>
				</v-layout>
			</v-responsive>
		</v-layout>
	</client-page>
</template>
<script>
import api from "@/api"
import ClientPage from "@/components/client/templates/client-page.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
export default {
    components: {
		ClientPage,
        VPasswordField
    },
    data(){
        return {
            tabIndex: 0,
            colors: {
                daehanbooks: "#22298b"
            },

            username: this.$cookies.get("username") || null,
            password: null,

            place: "",
            isRemember: false,
            pin: null,
            order: null,

            refreshToken: null,

            pinsHeaders: [
                { text: "핀번호", value: "pno", align: "center" }
            ],
			charge: null
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
        },
        validate(){
            try{
                if(!this.username) throw new Error("아이디를 입력해주세요");
                if(!this.password) throw new Error("비밀번호를 등록해주세요");
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async login(){
            if(this.validate()){
                let { refreshToken } = await api.plugins.nanuri.cash.auth.getRefreshToken({
                    username: this.username,
                    password: this.password
                });

                this.refreshToken = refreshToken;
				console.log({refreshToken})

                let { charge } = await api.v1.gshop.charges.get({ _id: this.$route.params._charge });

                this.charge = charge;
				console.log(charge)
                this.$nextTick(_ => this.tabIndex = 2 );
            }
        },
        async save(){
            await api.plugins.nanuri.cash.charge({ _charge: this.charge._id, refreshToken: this.refreshToken });

            alert("충전이 완료되었습니다");
			this.$router.push("/")
        }
    },
    computed: {
        pins(){
            return this.charge?.pins || [];
        }
    },
}
</script>
<style scoped>
.member-login .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-login .background-layout {
    position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0;
}
</style>