<template>
    <div>
        <!-- 개발확인부탁드립니다 -->
        <!-- S: 검색 -->
        <board-filter @searchKey="(searchKey) => { filter.searchKey = searchKey }" @searchValue="(searchValue) => {filter.searchValue = searchValue}" @search="search"/>
        <!-- E: 검색 -->

        <!-- S: 게시판 -->
        <v-data-table v-bind="{ ...table_primary }" :headers="headers" :items="notifications" no-data-text="등록된 공지사항이 없습니다" mobile-breakpoint="0" class="v-data-table--mobile v-data-table--board table-fixed" @click:row="(any, { item }) => showBoard(item)">
            <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            <template #[`item.subject`]="{ item }">
                <div data-idx="5" class="text-left text-truncate">{{ item.subject }}</div>
            </template>
        </v-data-table>
        <!-- E: 게시판 -->

        <!-- S: 페이징 -->
        <div class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </div>
        <!-- E: 페이징 -->
    </div>
</template>

<script>
import api from "@/api";
import { table_primary } from "@/assets/variables";

import BoardFilter from "@/components/client/board/board-filter.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";

const headers = [
    { width: "65%", align: "center", cellClass: "v-data-table--board__tit", text: "제목", value: "subject" },
    { width: "13%", align: "center", text: "작성자", value: "writer", formatter: (value) => value?.name || "관리자" },
    { width: "12%", align: "center", text: "작성일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "10%", align: "center", text: "조회", value: "viewCount" },
];

export default {
    components: {
        BoardFilter,
        PaginationComponent,
    },
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        filter: {
            searchKey: null,
            searchValue: null
        },
        table_primary,

        notifications: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,

        headers,
    }),
    computed: {
        items() {
            return [...this.notifications].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.notifications = [];
            this.limit = 10;
            this.summary = { totalCount: 0 };
        },
        async search() {
            console.log("검색")
            try {
                const { skip, limit, params } = this;
                console.log({ skip, limit, params })
                const { summary, notifications } = await api.v1.center.notifications.getNotifications({ headers: { skip, limit }, params: { ...this.filter } });

                this.summary = summary;
                this.notifications = notifications;
            } catch (error) {
                this.$handleError(error);
            }
        },
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
};
</script>
