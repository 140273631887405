<template>
    <page-section-primary :titWrapClass="titWrapClass" :titWrapSize="titWrapSize" :titWrapAlign="titWrapAlign" :titClass="titClass" :titSize="titSize" :txtClass="txtClass" :txtSize="txtSize">
        <template #pageSectionHead>
            <slot name="containerImmersive" />
            <slot name="pageSectionHead" />
        </template>

        <template #tit>
            <slot name="tit" />
        </template>

        <slot />

        <template #pageSectionFoot><slot name="pageSectionFoot" /></template>
    </page-section-primary>
</template>

<script>
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UPageSectionBasic from "@/sets/styles/pages/u-page-section-basic.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        titWrapClass: { type: String },
        titWrapSize: { type: String },
        titWrapAlign: { type: String },
        titClass: { type: String },
        titSize: { type: String },
        txtClass: { type: String },
        txtSize: { type: String },
    },
    components: {
        PageSectionPrimary,
        UPageSectionBasic,
        TitWrapPrimary,
    },
};
</script>

<style lang="scss" scoped></style>
