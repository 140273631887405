var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": _vm.search
    }
  }), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--mobile v-data-table--board table-fixed",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.notifications,
      "no-data-text": "등록된 공지사항이 없습니다",
      "mobile-breakpoint": "0"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.subject`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-left text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }], null, true)
  }, 'v-data-table', Object.assign({}, _vm.table_primary), false)), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }