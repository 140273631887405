<template>
    <v-col :cols="cols" class="py-0" :style="{ padding: padding }">
        <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="date" persistent width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field v-model="dateTime" :label="label" persistent-placeholder :outlined="outlined" :dense="dense" append-icon="event" clearable :hide-details="hideDetails" readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateModal = false">Cancel</v-btn>
                <v-btn text color="primary" @click="timeModal = true">OK</v-btn>
            </v-date-picker>
        </v-dialog>
        <v-dialog ref="dialog2" v-model="timeModal" :return-value.sync="time" persistent width="290px">
            <v-time-picker v-if="timeModal" v-model="time">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timeModal = false">Cancel</v-btn>
                <v-btn text color="primary" @click="set">OK</v-btn>
            </v-time-picker>
        </v-dialog>
    </v-col>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "일시" },
        value: { type: String, default: null },
        cols: { type: String, default: null },
        dense: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        hideDetails: { type: String, default: "auto" },
        padding: { type: String, default: null },
    },
    data() {
        return {
            dateTime: null,
            date: "",
            dateModal: false,
            time: "",
            timeModal: false,
        };
    },
    mounted() {
        if (this.value) this.sync();
    },
    methods: {
        sync() {
            var [date, time] = this.value.toDateTime().split(" ");
            this.dateTime = this.value.toDateTime();
            this.date = date;
            this.time = time;
        },
        set() {
            this.date = this.date + " " + this.time;
            this.$refs.dialog.save(this.date);
            this.$refs.dialog2.save(this.time);

            this.$emit("setDate", this.date);
            this.$emit("input", this.date);
            this.$emit("change", this.date);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>
