var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section-primary', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-container', {
          staticClass: "container--xxs"
        }, [_c('v-form', [_c('tit-form-section', {
          attrs: {
            "items": _vm.orderDetails
          }
        }, [_c('template', {
          slot: "주문명"
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "readonly": ""
          },
          model: {
            value: _vm.value.orderName,
            callback: function ($$v) {
              _vm.$set(_vm.value, "orderName", $$v);
            },
            expression: "value.orderName"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('template', {
          slot: "발신번호"
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "readonly": "",
            "placeholder": "070-8871-4701"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('template', {
          slot: "발송수단"
        }, [_c('v-radio-group', _vm._b({
          attrs: {
            "row": ""
          },
          model: {
            value: _vm.value.deliveryMethod,
            callback: function ($$v) {
              _vm.$set(_vm.value, "deliveryMethod", $$v);
            },
            expression: "value.deliveryMethod"
          }
        }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('v-radio', {
          attrs: {
            "label": "카카오톡알림톡",
            "value": "KAKAO"
          }
        }), _c('v-radio', {
          attrs: {
            "label": "문자발송",
            "value": "LMS"
          }
        })], 1)], 1), _c('template', {
          slot: "발신유형"
        }, [_c('v-radio-group', _vm._b({
          attrs: {
            "row": ""
          },
          model: {
            value: _vm.value.sendingMode,
            callback: function ($$v) {
              _vm.$set(_vm.value, "sendingMode", $$v);
            },
            expression: "value.sendingMode"
          }
        }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('v-radio', {
          attrs: {
            "label": "즉시발송",
            "value": "IMMEDIATE"
          }
        }), _c('v-radio', {
          attrs: {
            "label": "예약발송",
            "value": "SCHEDULED"
          }
        })], 1), _vm.value.sendingMode === 'SCHEDULED' ? _c('v-datetime-field', _vm._b({
          staticClass: "mt-2",
          attrs: {
            "label": "예약일시",
            "padding": "0"
          },
          model: {
            value: _vm.value.reserveDate,
            callback: function ($$v) {
              _vm.$set(_vm.value, "reserveDate", $$v);
            },
            expression: "value.reserveDate"
          }
        }, 'v-datetime-field', Object.assign({}, _vm.attrs_input), false)) : _vm._e()], 1), _c('template', {
          slot: "발신목적"
        }, [_c('v-select', _vm._b({
          attrs: {
            "items": _vm.purposeItems
          },
          model: {
            value: _vm.value.sendingPurpose,
            callback: function ($$v) {
              _vm.$set(_vm.value, "sendingPurpose", $$v);
            },
            expression: "value.sendingPurpose"
          }
        }, 'v-select', Object.assign({}, _vm.attrs_select_common), false)), _vm.value.sendingPurpose === '기타' ? _c('v-text-field', _vm._b({
          model: {
            value: _vm.sendingPurpose,
            callback: function ($$v) {
              _vm.sendingPurpose = $$v;
            },
            expression: "sendingPurpose"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false)) : _vm._e()], 1)], 2), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": _vm.goStep
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("다음으로 이동")])], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }