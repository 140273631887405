var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section-primary', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        var _vm$value, _vm$value2, _vm$value2$faceamt, _vm$value3;
        return [_c('v-container', {
          staticClass: "container--xxs"
        }, [_c('v-data-table', _vm._b({
          attrs: {
            "headers": _vm.headers,
            "items": _vm.excelItems.slice((_vm.page - 1) * _vm.limit, _vm.limit * _vm.page),
            "no-data-text": "내역이 없습니다."
          },
          scopedSlots: _vm._u([{
            key: `item.no`,
            fn: function (_ref) {
              var item = _ref.item;
              return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + _vm.excelItems.slice((_vm.page - 1) * _vm.limit, _vm.limit * _vm.page).indexOf(item) + 1) + " ")];
            }
          }, {
            key: `item.name`,
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(item.name) + " ")];
            }
          }, {
            key: `item.phone`,
            fn: function (_ref3) {
              var item = _ref3.item;
              return [_vm._v(" " + _vm._s(item.phone) + " ")];
            }
          }, {
            key: `item.quantity`,
            fn: function (_ref4) {
              var item = _ref4.item;
              return [_vm._v(" " + _vm._s(item.quantity) + " ")];
            }
          }], null, true)
        }, 'v-data-table', Object.assign({}, _vm.table_primary), false)), _c('div', {
          staticClass: "pagination-wrap pagination-wrap--sm"
        }, [_c('v-pagination', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1)], 1), _c('v-card', _vm._b({
          staticClass: "mx-auto mt-4",
          staticStyle: {
            "max-width": "282px"
          }
        }, 'v-card', Object.assign({}, _vm.card_primary), false), [_c('div', {
          staticClass: "pa-16px pa-md-24px"
        }, [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-divider', {
          staticClass: "border-light"
        }), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-primary', {
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v("상품명")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-secondary', {
          staticClass: "txt--dark",
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.productName))])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "border-light"
        }), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-primary', {
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v("권종")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-secondary', {
          staticClass: "txt--dark",
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$faceamt = _vm$value2.faceamt) === null || _vm$value2$faceamt === void 0 ? void 0 : _vm$value2$faceamt.format()) + "원")])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "border-light"
        }), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-primary', {
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v("유효기간")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-secondary', {
          staticClass: "txt--dark",
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v(_vm._s(_vm.displayExpiration))])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "border-light"
        }), _c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-primary', {
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v("발행개수")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-secondary', {
          staticClass: "txt--dark",
          attrs: {
            "txtSize": "txt--sm"
          }
        }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.quantity) + "개")])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "border-light"
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "primary--text",
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('b', {
          staticClass: "font-size-18 font-size-md-24"
        }, [_vm._v(_vm._s(_vm.totalAmount.format()))]), _c('span', {
          staticClass: "font-size-14 font-size-md-16 pl-2px"
        }, [_vm._v("원")])])])], 1)], 1)], 1)], 1)]), _c('div', {
          staticClass: "mt-16px mt-md-24px"
        }, [_c('txt-secondary', {
          staticClass: "txt--dark text-center",
          attrs: {
            "txtSize": "txt--lg"
          }
        }, [_vm._v("위의 내용으로 발송하시겠습니까?")])], 1), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('pay');
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("발송하기")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }