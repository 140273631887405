<template>
	<page-section-primary class="page-section--xs pb-0">
		<template #pageSectionFoot>
			<v-container class="container--xxs">
				<v-data-table v-bind="{ ...table_primary, }" :headers="headers" :items="excelItems.slice((page - 1) * limit, limit * page)" no-data-text="내역이 없습니다.">
					<template #[`item.no`]="{ item }">
						{{ (page - 1) * limit + excelItems.slice((page - 1) * limit, limit * page).indexOf(item) + 1}}
					</template>
					<template #[`item.name`]="{ item }">
						<!-- <v-text-field v-model="item.name" v-bind="{ ...attrs_input }" :placeholder="index === 0 ? '홍길동' : ''"/> -->
						{{ item.name }}
					</template>
					<template #[`item.phone`]="{ item }">
						<!-- <v-text-field v-model="item.phone" v-bind="{ ...attrs_input }" :placeholder="index === 0 ? '010-1234-5678' : ''" @input="formatPhone(index)"/> -->
						{{ item.phone }}
					</template>
					<template #[`item.quantity`]="{ item }">
						<!-- <v-text-field v-model="item.quantity" v-bind="{ ...attrs_input }" type="number" :placeholder="index === 0 ? '1' : '0'" @input="formatQuantity(index)"/> -->
						{{ item.quantity }}
					</template>
					<!-- <template #[`item.actions`]="{ item, index }">
						<v-btn v-if="item.name && item.phone && item.quantity" large icon text class="v-btn--text-fit rounded-circle pa-0" @click="deleteExcelItem(index)"><u-icon variant="" color="grey--text text--lighten-1">cancel</u-icon></v-btn>
					</template> -->
				</v-data-table>
				<div class="pagination-wrap pagination-wrap--sm">
					<v-pagination v-model="page" :length="pageCount" :total-visible="11"/>
				</div>
			</v-container>

			<v-card v-bind="{ ...card_primary }" class="mx-auto mt-4" style="max-width: 282px">
				<div class="pa-16px pa-md-24px">
					<v-row class="row--sm">
						<v-col cols="12">
							<v-divider class="border-light" />
							<div class="py-12px">
								<v-row align="center" justify="space-between" class="row--sm">
									<v-col cols="auto">
										<txt-primary txtSize="txt--sm">상품명</txt-primary>
									</v-col>
									<v-col cols="auto">
										<txt-secondary txtSize="txt--sm" class="txt--dark">{{ value?.productName }}</txt-secondary>
									</v-col>
								</v-row>
							</div>
							<v-divider class="border-light" />
							<div class="py-12px">
								<v-row align="center" justify="space-between" class="row--sm">
									<v-col cols="auto">
										<txt-primary txtSize="txt--sm">권종</txt-primary>
									</v-col>
									<v-col cols="auto">
										<txt-secondary txtSize="txt--sm" class="txt--dark">{{ value?.faceamt?.format() }}원</txt-secondary>
									</v-col>
								</v-row>
							</div>
							<v-divider class="border-light" />
							<div class="py-12px">
								<v-row align="center" justify="space-between" class="row--sm">
									<v-col cols="auto">
										<txt-primary txtSize="txt--sm">유효기간</txt-primary>
									</v-col>
									<v-col cols="auto">
										<txt-secondary txtSize="txt--sm" class="txt--dark">{{ displayExpiration }}</txt-secondary>
									</v-col>
								</v-row>
							</div>
							<v-divider class="border-light" />
							<div class="py-12px">
								<v-row align="center" justify="space-between" class="row--sm">
									<v-col cols="auto">
										<txt-primary txtSize="txt--sm">발행개수</txt-primary>
									</v-col>
									<v-col cols="auto">
										<txt-secondary txtSize="txt--sm" class="txt--dark">{{ value?.quantity }}개</txt-secondary>
									</v-col>
								</v-row>
							</div>
							<v-divider class="border-light" />
						</v-col>
						<v-col cols="12">
							<v-row align="center" justify="center" class="row--sm">
								<v-col cols="auto" class="primary--text">
									<div class="d-flex align-center"><b class="font-size-18 font-size-md-24">{{ totalAmount.format() }}</b><span class="font-size-14 font-size-md-16 pl-2px">원</span></div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</v-card>

			<div class="mt-16px mt-md-24px">
				<txt-secondary txtSize="txt--lg" class="txt--dark text-center">위의 내용으로 발송하시겠습니까?</txt-secondary>
			</div>

			<div class="btn-wrap">
				<v-row justify="center" class="row--xs">
					<v-col cols="auto">
						<v-btn v-bind="{ ...btn_primary }" x-large class="v-btn--width-fixed" @click="$emit('pay')">발송하기</v-btn>
					</v-col>
				</v-row>
			</div>
		</template>
	</page-section-primary>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

export default {
	components: {
		UIcon,
		TxtPrimary,
		TxtSecondary,
		TitFormSection,
		PageSectionPrimary
	},
	props: {
		value: { type: Object, default: () => ({}) },
		totalAmount: { type: Number, default: 0 },
		btn_primary: { type: Object, default: () => ({}) },
		card_primary: { type: Object, default: () => ({}) },
		table_primary: { type: Object, default: () => ({}) },
		excelItems: { type: Array, default: () => [] }
	},
	data() {
		return {
			headers: [
				{ width: "15%", align: "center", text: "순서", value: "no" },
				{ width: "30%", align: "center", text: "전화번호", value: "phone" },
				{ width: "25%", align: "center", text: "이름", value: "name" },
				{ width: "15%", align: "center", text: "수량", value: "quantity" },
				// { width: "15%", align: "center", text: "삭제", value: "actions" },
			],
			page: 1,
			pageCount: 1,
			limit: 5
		}
	},
	mounted() {
		this.pageCount = Math.ceil(this.excelItems.length / this.limit);
	},
	methods: {
	},
	computed: {
		displayExcelItems() {
			return this.excelItems.filter(el => el.phone && el.name && el.quantity)
		},
		displayExpiration() {
			const expirationMap = {
				30: "30일",
				60: "60일",
				90: "90일",
				1: "1년",
				5: "5년"
			}
			return expirationMap[this.value.expiration]
		} 
	}
}
</script>

<style>

</style>