<template>
	<page-section-primary class="page-section--xs pb-0">
		<template #pageSectionFoot>
			<v-container class="container--xxs">
				<v-form>
					<tit-form-section :items="orderDetails">
						<template slot="주문명">
							<v-text-field v-model="value.orderName" readonly v-bind="{ ...attrs_input }" />
						</template>
						<template slot="발신번호">
							<v-text-field readonly v-bind="{ ...attrs_input }" placeholder="070-8871-4701"/>
						</template>
						<template slot="발송수단">
							<v-radio-group v-model="value.deliveryMethod" v-bind="{ ...attrs_controls_common }" row>
								<v-radio label="카카오톡알림톡" value="KAKAO" />
								<v-radio label="문자발송" value="LMS" />
							</v-radio-group>
						</template>
						<template slot="발신유형">
							<v-radio-group v-model="value.sendingMode" v-bind="{ ...attrs_controls_common }" row>
								<v-radio label="즉시발송" value="IMMEDIATE" />
								<v-radio label="예약발송" value="SCHEDULED" />
							</v-radio-group>
							<v-datetime-field v-if="value.sendingMode === 'SCHEDULED'" v-model="value.reserveDate" label="예약일시" v-bind="{ ...attrs_input }" class="mt-2" padding="0" />
						</template>
						<template slot="발신목적">
							<v-select v-model="value.sendingPurpose" :items="purposeItems" v-bind="{ ...attrs_select_common }"/>
							<v-text-field v-if="value.sendingPurpose === '기타'" v-model="sendingPurpose" v-bind="{ ...attrs_input }" />
						</template>
					</tit-form-section>

					<div class="btn-wrap">
						<v-row justify="center" class="row--xs">
							<v-col cols="auto">
								<v-btn v-bind="{ ...btn_primary }" x-large class="v-btn--width-fixed" @click="goStep">다음으로 이동</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-container>
		</template>
	</page-section-primary>
</template>

<script>

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

export default {
	components: {
		VDatetimeField,
		TitFormSection,
		PageSectionPrimary
	},
	props: {
		value: { type: Object, default: () => ({}) },
		attrs_input: { type: Object, default: () => ({}) },
		btn_primary: { type: Object, default: () => ({}) },
		attrs_select_common: { type: Object, default: () => ({}) },
		attrs_controls_common: { type: Object, default: () => ({}) },
	},
	data() {
		return {
			orderDetails: [
			{ term: "주문명", outlined: true },
			{ term: "발신번호", outlined: true },
			{ term: "발송수단", outlined: true },
			{ term: "발신유형", outlined: true },
			{ term: "발신목적", outlined: true },
			],
			purposeItems: [
                { text: "사은품", value: "사은품" },
                { text: "면접/교육/설문", value: "면접/교육/설문" },
                { text: "이벤트 경품", value: "이벤트 경품" },
                { text: "직원복지", value: "직원복지" },
                { text: "고객관리", value: "고객관리" },
                { text: "기타", value: "기타" },
            ],
		}
	},
	methods: {
		validate() {
            try {
                if (!this.value.deliveryMethod) throw new Error("발송수단을 선택해주세요")
                if (!this.value.sendingMode) throw new Error("발신유형을 선택해주세요")  
                if (this.value.sendingMode === "SCHEDULED" && !this.value.reserveDate) throw new Error("예약 날짜를 선택해주세요")
				if (this.value.sendingMode === "SCHEDULED" && this.value.reserveDate && this.$dayjs(new Date) > this.$dayjs(this.value.reserveDate)) throw new Error("예약 날짜를 확인해주세요")
                if (!this.value.sendingPurpose) throw new Error("발신목적을 선택해주세요")  
                if (this.value.sendingPurpose === "기타" && !this.sendingPurpose) throw new Error("발신목적을 작성해주세요")
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
		goStep() {
            if (this.validate()) {
                this.$emit("go", 2)
            }
        },
	}
}
</script>

<style>

</style>