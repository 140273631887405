<template>
	<page-section-primary class="page-section--xs pb-0">
		<template #pageSectionFoot>
			<v-row class="row--xl">
				<v-col cols="12" md="auto" order-md="2">
					<v-card v-bind="{ ...card_secondary }" min-width="282px">
						<div class="pa-16px pa-md-24px">
							<tit-wrap-primary titWrapSize="pb-12px pb-md-16px" titWrapAlign="text-left" titSize="tit--xs">미리보기</tit-wrap-primary>
							<v-card v-bind="{ ...card_primary }">
								<v-textarea :value="preview" v-bind="{ ...attrs_input }" readonly no-resize rows="9"/>
							</v-card>
						</div>
					</v-card>
					<div class="mt-8px mt-md-12px text-right">
						<v-chip small color="primary lighten-5"><span class="primary--text">단문 {{ preview.length + 590}}/1000</span></v-chip>
					</div>
				</v-col>
				<v-col cols="12" md="" order-md="1">
					<v-form>
						<tit-form-section :items="templateInput">
							<template slot="회사명">
								<v-text-field :value="company.name" v-bind="{ ...attrs_input }" readonly placeholder="회사명을 입력해주세요"/>
							</template>
							<template slot="템플릿명">
								<v-row align="center" class="row--xxs">
									<v-col cols="12" sm="">
										<v-text-field v-model="template.name" v-bind="{ ...attrs_input }" placeholder="템플릿을 입력해주세요" :disabled="disabled"/>
									</v-col>
									<v-col cols="12" sm="auto"
										><!-- S: Dialog -->
										<u-dialog v-model="shows" title="템플릿 목록" cardInnerPadding="pt-0 px-0 pb-12px pb-md-20px pb-lg-24px">
											<template #activator="{ attrs, on }">
												<v-btn v-if="!template._id" v-bind="{ ...btn_primary, attrs }" v-on="on" x-large block color="grey" class="v-btn--input-comfortable">템플릿 불러오기</v-btn>
												<v-btn v-else v-bind="{ ...btn_primary, attrs }" x-large block color="grey" class="v-btn--input-comfortable" @click="initTemplate">템플릿 초기화</v-btn>
											</template>

											<div v-for="template in templates" :key="template._id" class="pa-12px px-md-16px border-b border-light">
												<v-row align="center" justify="space-between" class="row--xs">
													<v-col cols="12" md="">
														<txt-primary txtSize="txt--sm">{{ template?.name }}</txt-primary>
													</v-col>
													<v-col cols="12" md="auto">
														<v-row justify="end" class="row--xxs">
															<v-col cols="auto">
																<v-btn v-bind="{ ...btn_primary3 }" class="v-size--xx-small" @click="deleteTemplate(template)">삭제하기</v-btn>
															</v-col>
															<v-col cols="auto">
																<v-btn v-bind="{ ...btn_primary }" class="v-size--xx-small" @click="selectTemplate(template)">선택하기</v-btn>
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</div>
											<div class="pagination-wrap pagination-wrap--sm">
												<v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="handlePageChange" />
											</div>
										</u-dialog>
										<!-- E: Dialog -->
									</v-col>
								</v-row>
							</template>
							<template slot="제목">
								<v-text-field v-model="template.subject" v-bind="{ ...attrs_input }" placeholder="제목을 입력해주세요" :disabled="disabled" />
							</template>
							<template slot="컨텐츠">
								<div>
									<v-textarea v-model="template.content" v-bind="{ ...attrs_input }" placeholder="메시지를 입력해주세요. (ex. 인사말, 이벤트 내용)" :maxlength="282" :counter="282" no-resize rows="9" :disabled="disabled" @input="validateContent"/>
								</div>
							</template>
							<template slot="고객센터">
								<div>
									<v-text-field v-model="template.center" v-bind="{ ...attrs_input }" placeholder="고객센터" :maxlength="14" :counter="14" :disabled="disabled"/>
								</div>
								<div class="pt-8px pt-md-12px">
									<div>*지정된 특수문자만 사용이 가능합니다. 이외의 특수문자 입력 시 오류가 발생할 수 있습니다. <v-icon>mdi-help-circle-outline</v-icon></div>
									<div>*내용에 {이름}을 입력해주시면 각 문자 메시지에 자동으로 이름이 입력되어 발송됩니다.</div>
								</div>
								<div class="pt-8px pt-md-12px">
									<v-textarea :value="preview" v-bind="{ ...attrs_input }" readonly no-resize rows="9" />
								</div>
								<div class="pt-16px pt-md-24px">
									<v-checkbox v-model="template.isSaved" v-bind="{ ...attrs_controls_common }" label="템플릿으로 저장하기" :disabled="disabled" />
								</div>
							</template>
						</tit-form-section>
					</v-form>
				</v-col>
			</v-row>

			<div class="btn-wrap">
				<v-row justify="center" class="row--xs">
					<v-col cols="auto">
						<v-btn v-bind="{ ...btn_secondary }" x-large class="v-btn--width-fixed" @click="$emit('prev', 2)">이전으로 이동</v-btn>
					</v-col>
					<v-col cols="auto">
						<v-btn v-bind="{ ...btn_primary }" x-large class="v-btn--width-fixed" @click="goStep">다음으로 이동</v-btn>
					</v-col>
				</v-row>
			</div>
		</template>
	</page-section-primary>
</template>

<script>
import api from "@/api";
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

export default {
	components: {
		UIcon,
		UDialog,
		TxtPrimary,
		TitFormSection,
		TitWrapPrimary,
		PageSectionPrimary
	},
	props: {
		page: { type: Number, default: 0 },
		pageCount: { type: Number, default: 0 },
		value: { type: Object, default: () => ({}) },
		templates: { type: Array, default: () => ({}) },
		company: { type: Object, default: () => ({}) },
		carts: { type: Array, default: () => ({}) },

		attrs_input: { type: Object, default: () => ({}) },
		btn_primary: { type: Object, default: () => ({}) },
		card_primary: { type: Object, default: () => ({}) },
		btn_primary3: { type: Object, default: () => ({}) },
		btn_secondary: { type: Object, default: () => ({}) },
		card_secondary: { type: Object, default: () => ({}) },
		attrs_controls_common: { type: Object, default: () => ({}) },
	},
	data() {
		return {
			shows: false,
			dialog: false,
			template: {
				_id: null,
				name: null,
				subject: null,
				content: null,
				isSaved: false,
				center: null
			},
			templateInput: [
				{ term: "회사명", outlined: true },
				{ term: "템플릿명", outlined: true},
				{ term: "제목", outlined: true },
				{ term: "컨텐츠", outlined: true },
				{ term: "고객센터", outlined: true },
			],
		}
	},
	methods: {
		async deleteTemplate(template) {
            if (confirm("템플릿을 삭제하시겠습니까?")) {
                await api.v1.me.templates.delete({ _id: template._id })
                alert("템플릿이 삭제되었습니다");
                this.$emit("getTemplate")
            }
        },
		validate() {
            try {
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
		goStep() {
            if (this.validate()) {
				this.$emit("setTemplate", this.template)
                this.$emit("go", 4)
            }
        },
		initTemplate() {
			this.template._id = null;
			this.template.name = null;
            this.template.subject = null;
			this.template.content = null;
			this.template.center = null;
			this.template.isSaved = false;
		},
		selectTemplate(template) {
			this.template._id = template._id;
			this.template.name = template.name;
            this.template.subject = template.subject;
			this.template.content = template.content;
			this.template.center = template.center;
			this.template.isSaved = false;
			this.shows = false
        },
		handlePageChange(page) {
			this.$emit('getTemplate', page)
		},
		validateContent(value) {
			if (this.preview.length + 590 > 1000) {
				this.template.content = this.template.content.slice(0, -1);
			}
		}
	},
	computed: {
		preview() {
            let preview = `대한문고 상품권 핀번호 안내\n\n상품명 : 대한문고 상품권\n권종 : ${this?.carts[0]?.faceamt?.format()}원 권\n유효기간 : ${this.displayExpiration}\n\n핀번호\n1234-1234-1234-1234\n\n▶충전하러가기\n충전 URL\n\n고객센터: ${this?.template?.center || ""}`;

            let previewItems = [];

            if (previewItems.length) preview += previewItems.join("\n");
            preview = "\n" + preview.trim();

            if (this.template?.content) preview = `${this.template?.content?.trim?.()}\n` + preview

            return preview.trim();
        },
		displayExpiration() {
			let expirationMap = {
				30: "day",
				60: "day",
				90: "day",
				1: "year",
				5: "year",
			}
			return this.$dayjs().add(this.carts[0].expiration, expirationMap[this.carts[0].expiration]).format("YYYY-MM-DD")
		},
		disabled() {
			return !!this.template._id
		}
	},
	watch: {
		preview() {
			if (this.preview.length + 590 > 1000) {
				alert("1000자 이상 발송할 수 없습니다")
			}
		}
	}
}
</script>

<style>

</style>