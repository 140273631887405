<template>
    <div class="ml-auto mb-16px mb-md-24px" :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 500px' : ''">
        <v-row align="center" class="row--xxs">
            <v-col cols="12" sm="auto">
                <v-select v-model="searchKey" v-bind="{ ...attrs_input }" clearable :items="searchKeys" item-text="text" item-value="value" placeholder="전체" class="v-input--density-compact w-sm-180px" />
            </v-col>
            <v-col cols="12" sm="">
                <v-text-field v-model="searchValue" v-bind="{ ...attrs_input }" placeholder="검색어를 입력하세요" class="v-input--density-compact" />
            </v-col>
            <v-col cols="12" sm="auto">
                <v-btn v-bind="{ ...btn_primary3 }" class="v-btn--input-compact w-100 w-sm-auto" @click="$emit('search')">검색</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { attrs_input, btn_primary, btn_primary3, table_primary } from "@/assets/variables";

export default {
    components: {},
    data() {
        return {
            attrs_input,
            btn_primary,
            btn_primary3,
            table_primary,
            searchKey: null,
            searchValue: null,

            searchKeys: [
                { text: "전체", value: null },
                { text: "제목", value: "subject" },
                { text: "제목 + 내용", value: "subjectcontent" },
            ],
        };
    },
    methods: {
        searchKey() {
            this.$emit("searchKey", this.searchKey);
        },
        searchValue() {
            this.$emit("searchValue", this.searchValue);
        },
    },
};
</script>
