var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-reception",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 46 46"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M20.4,6.4c-4.2,0-7.5,3.4-7.5,7.6c0,2.2,0.9,4.1,2.4,5.5c0,0,0.1,0.1,0.1,0.1c1.3,1.2,3.1,2,5.1,2c2,0,3.8-0.8,5.1-2\n\t\tc0,0,0.1,0,0.1-0.1c1.5-1.4,2.4-3.3,2.4-5.5C27.9,9.8,24.5,6.4,20.4,6.4z M20.4,19.9c-1.3,0-2.6-0.5-3.6-1.2\n\t\tc0.7-1.3,2.1-2.1,3.6-2.1s2.8,0.8,3.6,2.1C23,19.4,21.7,19.9,20.4,19.9z M19.1,13.7v-0.5c0-0.7,0.6-1.3,1.3-1.3\n\t\tc0.7,0,1.3,0.6,1.3,1.3v0.5c0,0.7-0.6,1.3-1.3,1.3C19.7,15,19.1,14.4,19.1,13.7z M25.2,17.5L25.2,17.5c-0.6-0.9-1.5-1.6-2.4-2\n\t\tc0.4-0.5,0.6-1.1,0.6-1.8v-0.5c0-1.6-1.3-2.9-2.9-2.9s-3,1.3-3,2.9v0.5c0,0.7,0.2,1.3,0.6,1.8c-1,0.4-1.8,1.1-2.4,2\n\t\tc-0.7-1-1.2-2.2-1.2-3.5c0-3.3,2.7-5.9,5.9-5.9c3.3,0,5.9,2.7,5.9,5.9C26.3,15.3,25.9,16.5,25.2,17.5z"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.8,37.9h-4.4c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h4.4c0.5,0,0.8-0.4,0.8-0.8S25.3,37.9,24.8,37.9z"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.8,33.3H13.2c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h11.7c0.5,0,0.8-0.4,0.8-0.8S25.3,33.3,24.8,33.3z"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.8,28.7H13.2c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h11.7c0.5,0,0.8-0.4,0.8-0.8C25.6,29.1,25.3,28.7,24.8,28.7z\n\t\t"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.2,24.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6\n\t\tc0.1,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6C10.5,24.7,10.4,24.5,10.2,24.4z"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.2,29c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6\n\t\tc0.1,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2s0.2-0.4,0.2-0.6C10.5,29.3,10.4,29.1,10.2,29z"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.7,33.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6C9.2,34.9,9.5,35,9.7,35\n\t\tc0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C10.1,33.4,9.9,33.3,9.7,33.3z"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.8,24.1H13.2c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h11.7c0.5,0,0.8-0.4,0.8-0.8S25.3,24.1,24.8,24.1z"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.3,12.7L43.3,12.7c-0.8-0.5-1.7-0.6-2.6-0.3c-0.9,0.2-1.6,0.8-2.1,1.6L28.3,31.8c-0.1,0.1-0.1,0.2-0.1,0.3l-0.7,6.5\n\t\tc0,0.3,0.1,0.6,0.4,0.8c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5-0.2l5.3-3.9c0.1-0.1,0.2-0.2,0.2-0.2l10.3-17.9\n\t\tC45.5,15.8,44.9,13.7,43.3,12.7z M29.3,37l0.4-3.4L32,35L29.3,37z M33.2,33.8L30.1,32l8.3-14.3l3.1,1.8L33.2,33.8z M43.1,16.6\n\t\tL43.1,16.6L42.3,18l-3.1-1.8l0.8-1.4c0.2-0.4,0.6-0.7,1.1-0.8c0.5-0.1,0.9-0.1,1.4,0.2c0.4,0.2,0.7,0.6,0.8,1.1\n\t\tC43.4,15.7,43.4,16.2,43.1,16.6z"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.6,4.5C4.6,4,5,3.6,5.5,3.6h29.9c0.5,0,0.8,0.4,0.8,0.8v8.9c0,0.5,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8V4.5\n\t\tc0-1.4-1.1-2.5-2.5-2.5H5.5C4.1,2,3,3.1,3,4.5v5.7h1.6V4.5z"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.8,18c0.5,0,0.8-0.4,0.8-0.8V14c0-0.5-0.4-0.8-0.8-0.8S3,13.5,3,14v3.2C3,17.6,3.4,18,3.8,18z"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.5,20.4C4.5,20.4,4.5,20.3,4.5,20.4c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1\n\t\tc0,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0\n\t\tc0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1s0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1\n\t\tc0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1s0.1,0,0.1,0\n\t\tc0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0s0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0\n\t\tc0,0,0,0,0-0.1s0,0,0-0.1c0,0,0.1-0.1,0.1-0.1s0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1\n\t\tc0-0.1,0-0.1,0-0.2C4.5,20.5,4.5,20.4,4.5,20.4z"
    }
  }), _c('path', {
    attrs: {
      "d": "M37,34.7c-0.5,0-0.8,0.4-0.8,0.8v6c0,0.4-0.4,0.8-0.8,0.8H5.5c-0.5,0-0.8-0.4-0.8-0.8v-3.4V24.9c0-0.6-0.4-1.1-0.8-1.1\n\t\tS3,24.2,3,24.9v16.7C3,42.9,4.1,44,5.5,44h29.9c1.4,0,2.5-1.1,2.5-2.5v-6C37.8,35.1,37.4,34.7,37,34.7z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }