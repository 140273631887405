<template>
	<page-section-primary class="page-section--xs pb-0">
		<template #pageSectionFoot>
			<v-container class="container--xxs">
				<div class="pb-16px pb-md-20px pb-lg-24px">
					<div>수신자 등록 엑셀 업로드 방법</div>
					<div>1) 양식 다운로드 버튼을 눌러 양식을 다운받습니다.</div>
					<div>2) 핸드폰 번호 이름 수량을 두번째 줄부터 입력합니다 (1.000명까지 입력 가능합니다).</div>
					<div>3) 파일을 저장 후 엑셀로 업로드 클릭</div>
					<div>4) 입력된 연락처와 수량을 최종 확인 후 다음으로 이동</div>
				</div>
				<div class="pb-16px pb-md-20px pb-lg-24px">
					<v-row align="center" justify="space-between" class="row--xs">
						<v-col cols="auto">
							<v-row align="center" class="row--xxs">
								<v-col cols="auto">
									<v-btn v-bind="{ ...btn_primary2 }" class="v-size--xx-small" @click="sampleDownload">양식 다운로드 <u-icon class="ml-4px ml-md-8px">file_download</u-icon></v-btn>
								</v-col>
								<v-col cols="auto">
									<v-btn v-bind="{ ...btn_primary }" class="v-size--xx-small" @click="$refs.uploader.$refs.input.click()">엑셀로 업로드 <u-icon class="ml-4px ml-md-8px">file_upload</u-icon></v-btn>
									<v-file-input ref="uploader" class="d-none" @change="(file) => upload(file)" />
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="auto">
							<span class="font-size-14 font-weight-medium pr-4px pr-md-8px">등록된 수신자</span>
							<b class="font-size-18 font-size-md-20"><span class="red--text text--lighten-1">{{ display }}</span>/{{ quantity }}</b>
						</v-col>
					</v-row>
				</div>
				<v-data-table v-bind="{ ...table_primary, }" :headers="headers" :items="excelItems.slice((page - 1) * limit, limit * page)" no-data-text="내역이 없습니다.">
					<template #[`item.no`]="{ item }">
						{{ (page - 1) * limit + excelItems.slice((page - 1) * limit, limit * page).indexOf(item) + 1}}
					</template>
					<template #[`item.name`]="{ item, index }">
						<v-text-field v-model="item.name" v-bind="{ ...attrs_input }" :placeholder="index === 0 ? '홍길동' : ''"/>
					</template>
					<template #[`item.phone`]="{ item, index }">
						<v-text-field v-model="item.phone" v-bind="{ ...attrs_input }" :placeholder="index === 0 ? '010-1234-5678' : ''" @input="formatPhone(index)"/>
					</template>
					<template #[`item.quantity`]="{ item, index }">
						<v-text-field v-model="item.quantity" v-bind="{ ...attrs_input }" type="number" :placeholder="index === 0 ? '1' : '0'" @input="formatQuantity(index)"/>
					</template>
					<template #[`item.actions`]="{ item, index }">
						<v-btn v-if="item.name && item.phone && item.quantity" large icon text class="v-btn--text-fit rounded-circle pa-0" @click="deleteExcelItem(index)"><u-icon variant="" color="grey--text text--lighten-1">cancel</u-icon></v-btn>
					</template>
				</v-data-table>
				<div class="pagination-wrap pagination-wrap--sm">
					<v-pagination v-model="page" :length="pageCount" :total-visible="11"/>
				</div>

				<div class="btn-wrap">
					<v-row justify="center" class="row--xs">
						<v-col cols="auto">
							<v-btn v-bind="{ ...btn_secondary }" x-large class="v-btn--width-fixed" @click="$emit('prev', 1)">이전으로 이동</v-btn>
						</v-col>
						<v-col cols="auto">
							<v-btn v-bind="{ ...btn_primary }" x-large class="v-btn--width-fixed" @click="goStep">다음으로 이동</v-btn>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</template>
	</page-section-primary>
</template>

<script>
import api from "@/api";
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

import XLSX from "xlsx";
import download from "downloadjs";

export default {
	components: {
		UIcon,
		TitFormSection,
		PageSectionPrimary
	},
	props: {
		value: { type: Object, default: () => ({}) },
		display: { type: Number, default: 0 },
		quantity: { type: Number, default: 0 },
		excelItems: { type: Array, default: () => [] },
		attrs_input: { type: Object, default: () => ({}) },
		btn_primary: { type: Object, default: () => ({}) },
		btn_primary2: { type: Object, default: () => ({}) },
		table_primary: { type: Object, default: () => ({}) },
		btn_secondary: { type: Object, default: () => ({}) },
	},
	data() {
		return {
			headers: [
				{ width: "15%", align: "center", text: "순서", value: "no" },
				{ width: "30%", align: "center", text: "전화번호", value: "phone" },
				{ width: "25%", align: "center", text: "이름", value: "name" },
				{ width: "15%", align: "center", text: "수량", value: "quantity" },
				{ width: "15%", align: "center", text: "삭제", value: "actions" },
			],

			page: 1,
			pageCount: 1,
			limit: 5
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.pageCount = Math.ceil(this.excelItems.length / this.limit);
		},
		validate() {
            try {
                if (this.quantity != this.display) throw new Error(`${this.quantity}중에 ${this.display}개를 입력하셨습니다\n핸드폰번호와 이름을 모두 입력해주세요`)
				for (let excelItem of this.excelItems) {
					if (this.quantity !== this.display) {
						if (!/^010-\d{4}-\d{4}$/.test(excelItem.phone)) throw new Error("올바른 핸드폰 번호를 입력해주세요")
					}
				}
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
		goStep() {
            if (this.validate()) {
                this.$emit("go", 3)
            }
        },
		deleteExcelItem(index) {
			index = (this.limit * (this.page -1)) + (index)
            this.excelItems.splice(index, 1)
            this.excelItems.push({ name: null, phone: null, quatity: null })
        },
		sampleDownload() {
            api.getResource("/assets/sample1.xlsx").then((file) => download(file, "샘플다운로드.xlsx"));
        },
		formatPhone(index) {
			index = (this.limit * (this.page -1)) + (index)
			if (this.excelItems[index].phone.length === 11) {
				this.excelItems[index].phone = this.excelItems[index].phone.phoneNumberFormat();
				for (let i = 0; i < this.excelItems.length; i++) {
					if (this.excelItems[i].phone !== null && i !== index) {
						if (this.excelItems[index].phone === this.excelItems[i].phone) {
							alert("동일한 번호를 입력할 수 없습니다")
							this.excelItems[index].phone = null
						}
					}
				}
			}
		},
		formatQuantity(index) {
			index = (this.limit * (this.page -1)) + (index)
			if (Number(this.excelItems[index].quantity) === NaN) {
				alert("숫자만 입력 가능합니다")
				this.excelItems[index].quantity = 0;
			}

			if (Number(this.excelItems[index].quantity) < 0) {
				alert("음수를 입력할 수 없습니다")
				this.excelItems[index].quantity = 0;
			}

			if (Number(this.excelItems[index].quantity) > 30) {
				alert("수량은 30까지 입력할 수 있습니다")
				this.excelItems[index].quantity = 30;
			}
		},
		upload(file) {
            if (!file) return;
            let fileName = file.name.split(".");
            if (fileName[fileName.length - 1] !== "xlsx") {
                return alert("엑셀파일만 가능합니다");
            }
            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach((sheetName) => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });

                if (workSheets[0].length > this.quantity) {
                    alert("수신자는 상품권 수량을 초과할 수 없습니다");
                    return;
                }

                for (let i = 0; i < workSheets[0].length; i++) {
                    let row = workSheets[0][i];

					const uploadedPhone = row["핸드폰번호"];
					if (this.excelItems.some(item => item.phone === uploadedPhone)) {
						alert("중복된 핸드폰 번호가 있습니다");
						return;
					}

                    this.excelItems[i].name = row["이름"];
                    this.excelItems[i].phone = row["핸드폰번호"];
					this.excelItems[i].quantity = Number(row["수량"])
                }
            };
            reader.readAsBinaryString(file);
        },
	}
}
</script>

<style>

</style>