<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 16" class="icon-svg icon-arrow-rounded" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path
            d="M12.3,0.8l-12,11.7C0.1,12.6,0,12.9,0,13.2c0,0.3,0.1,0.5,0.3,0.7l1.4,1.3c0.4,0.4,1,0.4,1.4,0L13,5.6l9.9,9.7
	c0.4,0.4,1,0.4,1.4,0l1.4-1.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7v0l-12-11.7C13.3,0.4,12.7,0.4,12.3,0.8z" />
    </svg>

    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 26" class="icon-svg icon-arrow-rounded" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path
            d="M0.8,13.7l11.7,12c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l1.3-1.4c0.4-0.4,0.4-1,0-1.4L5.6,13l9.7-9.9
	c0.4-0.4,0.4-1,0-1.4l-1.3-1.4C13.7,0.1,13.4,0,13.2,0s-0.5,0.1-0.7,0.3h0l-11.7,12C0.4,12.7,0.4,13.3,0.8,13.7z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 10px;
            }
            &-x-small {
                width: 12px;
            }
            &-small {
                width: 14px;
            }
            &-default {
                width: 16px;
            }
            &-large {
                width: 18px;
            }
            &-x-large {
                width: 20px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
