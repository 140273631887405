<template>
    <client-page>
        <page-section-primary class="page-section--first page-section--last">
            <template #tit>나누리 상품권 구매하기</template>

            <v-row>
                <v-col cols="12" md="9">
                    <v-card v-bind="{ ...card_secondary }" class="d-flex h-100">
                        <div class="pa-16px pa-md-24px pa-lg-40px my-auto">
                            <tit-wrap-primary titWrapSize="tit-wrap--sm" titWrapAlign="text-left" titSize="tit--sm"> 구매하려는 상품권의 정보를 입력해주세요. </tit-wrap-primary>
                            <v-row class="row--xs">
                                <v-col cols="12" md="">
                                    <div class="pb-8px pb-md-12px">
                                        <txt-secondary class="txt--dark">상품명</txt-secondary>
                                    </div>
                                    <v-row align="center" class="row--xxs">
                                        <v-col>
                                            <!-- <v-select v-bind="{ ...attrs_input, ...$attrs }" placeholder="선택" class="white" /> -->
                                            <v-text-field v-bind="{ ...attrs_input, ...$attrs }" readonly type="number" placeholder="나누리 상품권" class="white">
                                                <!-- <template v-slot:append> 개 </template> -->
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="pb-8px pb-md-12px">
                                        <txt-secondary class="txt--dark">권종</txt-secondary>
                                    </div>
                                    <v-row align="center" class="row--xxs">
                                        <v-col>
                                            <v-select v-model="cart.faceamt" v-bind="{ ...attrs_input, ...$attrs, items: faceamtItems }" placeholder="6,000원" class="white" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <div class="pb-8px pb-md-12px">
                                        <txt-secondary class="txt--dark">유효기간</txt-secondary>
                                    </div>
                                    <v-row align="center" class="row--xxs">
                                        <v-col>
                                            <v-select v-model="cart.expiration" v-bind="{ ...attrs_input, ...$attrs, items: expirationItems }" item-text="text" item-value="value" placeholder="30일" class="white" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <div class="pb-8px pb-md-12px">
                                        <txt-secondary class="txt--dark">발행개수</txt-secondary>
                                    </div>
                                    <v-row align="center" class="row--xxs">
                                        <v-col>
                                            <v-text-field v-model="cart.quantity" v-bind="{ ...attrs_input, ...$attrs }" type="number" placeholder="0" class="white">
                                                <template v-slot:append> 개 </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <v-card v-bind="{ ...card_primary }">
                        <div class="pa-16px pa-md-24px">
                            <tit-wrap-primary titWrapSize="pb-12px pb-md-16px" titWrapAlign="text-left" titSize="tit--xs">구매정보</tit-wrap-primary>
                            <v-row class="row--sm">
                                <v-col cols="12">
                                    <v-divider class="border-light" />
                                    <div class="py-12px">
                                        <v-row align="center" justify="space-between" class="row--sm">
                                            <v-col cols="auto">
                                                <txt-primary txtSize="txt--sm">상품명</txt-primary>
                                            </v-col>
                                            <v-col cols="auto">
                                                <txt-secondary txtSize="txt--sm" class="txt--dark">나누리 상품권</txt-secondary>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="border-light" />
                                    <div class="py-12px">
                                        <v-row align="center" justify="space-between" class="row--sm">
                                            <v-col cols="auto">
                                                <txt-primary txtSize="txt--sm">권종</txt-primary>
                                            </v-col>
                                            <v-col cols="auto">
                                                <txt-secondary txtSize="txt--sm" class="txt--dark">{{ cart?.faceamt?.format?.() }}원</txt-secondary>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="border-light" />
                                    <div class="py-12px">
                                        <v-row align="center" justify="space-between" class="row--sm">
                                            <v-col cols="auto">
                                                <txt-primary txtSize="txt--sm">유효기간</txt-primary>
                                            </v-col>
                                            <v-col cols="auto">
                                                <txt-secondary txtSize="txt--sm" class="txt--dark">{{ displayExpiration }}</txt-secondary>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="border-light" />
                                    <div class="py-12px">
                                        <v-row align="center" justify="space-between" class="row--sm">
                                            <v-col cols="auto">
                                                <txt-primary txtSize="txt--sm">발행개수</txt-primary>
                                            </v-col>
                                            <v-col cols="auto">
                                                <txt-secondary txtSize="txt--sm" class="txt--dark">{{ cart?.quantity || 0 }}개</txt-secondary>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="border-light" />
                                </v-col>
                                <v-col cols="12">
                                    <v-row align="center" justify="space-between" class="row--sm">
                                        <v-col cols="auto">
                                            <txt-primary txtSize="txt--sm">총 구매금액</txt-primary>
                                        </v-col>
                                        <v-col cols="auto" class="primary--text">
                                            <div class="d-flex align-center"><b class="font-size-18 font-size-md-24">{{ totalAmount?.format() }}</b><span class="font-size-14 font-size-md-16 pl-2px">원</span></div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn v-bind="{ ...btn_primary }" x-large block @click="order">바로 구매하기</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section-primary>
    </client-page>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_controls_common, btn_primary, btn_primary2, btn_primary3, btn_secondary, btn_tertiary, card_primary, card_secondary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";

export default {
    components: {
        ClientPage,
        PageSectionPrimary,
        TitWrapPrimary,
        TitPrimary,
        TxtPrimary,
        TxtSecondary,
        IconArrowRounded,
    },
    data() {
        return {
            attrs_input,
            attrs_controls_common,
            btn_primary,
            btn_primary2,
            btn_primary3,
            btn_secondary,
            btn_tertiary,
            card_primary,
            card_secondary,

            cart: {
                productName: "나누리 상품권",
                faceamt: 5000,
                salePrice: 5000,
                expiration: 30,
                quantity: 1
            },

            carts: [],

            expirationItems: [
                { text: "30일", value: 30  },
                { text: "60일", value: 60  },
                { text: "90일", value: 90  },
                { text: "1년", value: 1  },
                { text: "5년", value: 5  },
            ],

            faceamtItems: [
                { text: "5,000원", value: 5000 },
                { text: "10,000원", value: 10000 },
                { text: "30,000원", value: 30000 },
                { text: "50,000원", value: 50000 },
            ]
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            if (!this.accessToken) {
                alert("로그인 후 이용할 수 있습니다.")
                this.$router.push("/login");
            }
        },
        validate() {
            try {
                if (!this.cart.faceamt) throw new Error("권종을 선택해주세요");
                if (!this.cart.expiration) throw new Error("유효기간을 선택해주세요");
                if (this.cart.quantity <= 0) throw new Error("발행개수를 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
        async order() {
            if (this.validate()) {
                let { user } = await api.v1.me.get();
                if (user.point < this.totalAmount) {
                    alert("보유 포인트가 부족합니다");
                    return;
                }
                await this.$store.dispatch("pickup", { carts: [ this.cart ] })
                this.$router.push("/buying/order")
            }
        },
    },
    computed: {
        totalAmount(){
            return this.cart.faceamt * this.cart.quantity;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        displayExpiration() {
			const expirationMap = {
				30: "30일",
				60: "60일",
				90: "90일",
				1: "1년",
				5: "5년"
			}
			return expirationMap[this.cart?.expiration]
		} 
    },
    watch: {
        "cart.faceamt"() {
            this.cart.salePrice = this.cart.faceamt;
        },
        "cart.quantity"() {
            if (Number(this.cart.quantity) > 1000) {
                alert("발행개수는 1,000개 이합니다")
                this.cart.quantity = 1000;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
