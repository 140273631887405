var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("서비스 소개")];
      },
      proxy: true
    }])
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/about/about-img.jpg"
    }
  }), _c('router-link', {
    attrs: {
      "to": "/buying"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/about/about-img2.jpg"
    }
  })], 1), _c('v-img', {
    attrs: {
      "src": "/images/sub/about/about-img3.jpg"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }