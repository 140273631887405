var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-rounded",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M12.3,0.8l-12,11.7C0.1,12.6,0,12.9,0,13.2c0,0.3,0.1,0.5,0.3,0.7l1.4,1.3c0.4,0.4,1,0.4,1.4,0L13,5.6l9.9,9.7\n\tc0.4,0.4,1,0.4,1.4,0l1.4-1.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7v0l-12-11.7C13.3,0.4,12.7,0.4,12.3,0.8z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-rounded",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 26"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0.8,13.7l11.7,12c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l1.3-1.4c0.4-0.4,0.4-1,0-1.4L5.6,13l9.7-9.9\n\tc0.4-0.4,0.4-1,0-1.4l-1.3-1.4C13.7,0.1,13.4,0,13.2,0s-0.5,0.1-0.7,0.3h0l-11.7,12C0.4,12.7,0.4,13.3,0.8,13.7z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }