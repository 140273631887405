<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 46 46" class="icon-svg icon-order-details">
        <g>
            <path
                d="M26.1,18.2H11.3c-0.5,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h14.8c0.4,0,0.8-0.4,0.8-0.8C26.9,18.6,26.5,18.2,26.1,18.2z
		" />
            <path d="M26.1,22.1H11.3c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h14.8c0.4,0,0.8-0.4,0.8-0.8S26.5,22.1,26.1,22.1z" />
            <path d="M26.1,26H11.3c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h14.8c0.4,0,0.8-0.4,0.8-0.8C26.9,26.4,26.5,26,26.1,26z" />
            <path d="M26.1,29.9H11.3c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h14.8c0.4,0,0.8-0.4,0.8-0.8S26.5,29.9,26.1,29.9z" />
            <path
                d="M33.9,2c-2,0-3.8,0.7-5.2,1.8H8c-1.6,0-3,1.3-3,3v29.8c0,1.7,1.3,3,3,3h2.6V41c0,1.7,1.3,3,3,3H35c1.6,0,3-1.3,3-3V29.6
		c0-0.5-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8V41c0,0.7-0.6,1.3-1.3,1.3H13.6c-0.7,0-1.3-0.6-1.3-1.3v-1.4h17.2v0c1.6,0,3-1.3,3-3
		V19.1c0.5,0.1,1,0.1,1.5,0.1c0.9,0,1.7-0.1,2.5-0.4v3.3c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-4c2.7-1.5,4.5-4.3,4.5-7.6
		C42.5,5.9,38.6,2,33.9,2z M30.8,36.6c0,0.7-0.6,1.3-1.3,1.3H8c-0.7,0-1.3-0.6-1.3-1.3V6.8C6.6,6,7.2,5.4,8,5.4h19
		c-1,1.4-1.7,3-1.7,4.8h-14c-0.5,0-0.8,0.4-0.8,0.8v3.5c0,0.5,0.4,0.8,0.8,0.8h14.8c0.2,0,0.4-0.1,0.5-0.2c1,1.6,2.4,2.8,4.2,3.5
		V36.6z M25.3,11.9v1.8H12.1v-1.8H25.3z M33.9,17.6c-3.8,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S37.7,17.6,33.9,17.6z" />
            <path d="M33.9,8.7c-0.5,0-0.8,0.4-0.8,0.8v5.6c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V9.5C34.7,9.1,34.3,8.7,33.9,8.7z" />
            <circle cx="33.9" cy="6.9" r="0.8" />
            <path
                d="M37.8,25.5C37.8,25.4,37.8,25.4,37.8,25.5c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0s0,0-0.1,0c0,0-0.1-0.1-0.1-0.1
		c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
		c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
		c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1s0.1,0,0.2,0
		c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0
		c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.2c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0,0,0-0.1-0.1-0.1C37.9,25.6,37.9,25.5,37.8,25.5z" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-order-details {
    width: 46px;
}
</style>
