var render = function render(){
  var _vm$cart, _vm$cart$faceamt, _vm$cart$faceamt$form, _vm$cart2, _vm$totalAmount;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("나누리 상품권 구매하기")];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex h-100"
  }, 'v-card', Object.assign({}, _vm.card_secondary), false), [_c('div', {
    staticClass: "pa-16px pa-md-24px pa-lg-40px my-auto"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titWrapSize": "tit-wrap--sm",
      "titWrapAlign": "text-left",
      "titSize": "tit--sm"
    }
  }, [_vm._v(" 구매하려는 상품권의 정보를 입력해주세요. ")]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('txt-secondary', {
    staticClass: "txt--dark"
  }, [_vm._v("상품명")])], 1), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "white",
    attrs: {
      "readonly": "",
      "type": "number",
      "placeholder": "나누리 상품권"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, _vm.$attrs), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('txt-secondary', {
    staticClass: "txt--dark"
  }, [_vm._v("권종")])], 1), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', _vm._b({
    staticClass: "white",
    attrs: {
      "placeholder": "6,000원"
    },
    model: {
      value: _vm.cart.faceamt,
      callback: function ($$v) {
        _vm.$set(_vm.cart, "faceamt", $$v);
      },
      expression: "cart.faceamt"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, _vm.$attrs, {
    items: _vm.faceamtItems
  }), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('txt-secondary', {
    staticClass: "txt--dark"
  }, [_vm._v("유효기간")])], 1), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', _vm._b({
    staticClass: "white",
    attrs: {
      "item-text": "text",
      "item-value": "value",
      "placeholder": "30일"
    },
    model: {
      value: _vm.cart.expiration,
      callback: function ($$v) {
        _vm.$set(_vm.cart, "expiration", $$v);
      },
      expression: "cart.expiration"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, _vm.$attrs, {
    items: _vm.expirationItems
  }), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('txt-secondary', {
    staticClass: "txt--dark"
  }, [_vm._v("발행개수")])], 1), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "white",
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._v(" 개 ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.cart.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.cart, "quantity", $$v);
      },
      expression: "cart.quantity"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, _vm.$attrs), false))], 1)], 1)], 1)], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', _vm._b({}, 'v-card', Object.assign({}, _vm.card_primary), false), [_c('div', {
    staticClass: "pa-16px pa-md-24px"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titWrapSize": "pb-12px pb-md-16px",
      "titWrapAlign": "text-left",
      "titSize": "tit--xs"
    }
  }, [_vm._v("구매정보")]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "py-12px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("상품명")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-secondary', {
    staticClass: "txt--dark",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("나누리 상품권")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "py-12px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("권종")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-secondary', {
    staticClass: "txt--dark",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v(_vm._s((_vm$cart = _vm.cart) === null || _vm$cart === void 0 ? void 0 : (_vm$cart$faceamt = _vm$cart.faceamt) === null || _vm$cart$faceamt === void 0 ? void 0 : (_vm$cart$faceamt$form = _vm$cart$faceamt.format) === null || _vm$cart$faceamt$form === void 0 ? void 0 : _vm$cart$faceamt$form.call(_vm$cart$faceamt)) + "원")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "py-12px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("유효기간")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-secondary', {
    staticClass: "txt--dark",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v(_vm._s(_vm.displayExpiration))])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "py-12px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("발행개수")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-secondary', {
    staticClass: "txt--dark",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v(_vm._s(((_vm$cart2 = _vm.cart) === null || _vm$cart2 === void 0 ? void 0 : _vm$cart2.quantity) || 0) + "개")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("총 구매금액")])], 1), _c('v-col', {
    staticClass: "primary--text",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('b', {
    staticClass: "font-size-18 font-size-md-24"
  }, [_vm._v(_vm._s((_vm$totalAmount = _vm.totalAmount) === null || _vm$totalAmount === void 0 ? void 0 : _vm$totalAmount.format()))]), _c('span', {
    staticClass: "font-size-14 font-size-md-16 pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": ""
    },
    on: {
      "click": _vm.order
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("바로 구매하기")])], 1)], 1)], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }