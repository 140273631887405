var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({}, 'v-card', Object.assign({}, _vm.card_primary), false), [_c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.value >= 1,
      "step": "1"
    }
  }, [_c('div', {
    staticClass: "v-stepper__icon"
  }, [_c('icon-order-details')], 1), _c('tit-primary', {
    staticClass: "grey--text",
    attrs: {
      "titSize": "tit--xs"
    }
  }, [_vm._v("STEP 1")]), _c('txt-primary', {
    staticClass: "grey--text text--lighten-1 mt-2px",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("주문정보")]), _c('div', {
    staticClass: "v-stepper__arrow"
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "right",
      "color": "grey--text text--lighten-3"
    }
  })], 1)], 1), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.value >= 2,
      "step": "2"
    }
  }, [_c('div', {
    staticClass: "v-stepper__icon"
  }, [_c('icon-reception')], 1), _c('tit-primary', {
    staticClass: "grey--text",
    attrs: {
      "titSize": "tit--xs"
    }
  }, [_vm._v("STEP 2")]), _c('txt-primary', {
    staticClass: "grey--text text--lighten-1 mt-2px",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("수신자 등록")]), _c('div', {
    staticClass: "v-stepper__arrow"
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "right",
      "color": "grey--text text--lighten-3"
    }
  })], 1)], 1), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.value >= 3,
      "step": "3"
    }
  }, [_c('div', {
    staticClass: "v-stepper__icon"
  }, [_c('icon-template')], 1), _c('tit-primary', {
    staticClass: "grey--text",
    attrs: {
      "titSize": "tit--xs"
    }
  }, [_vm._v("STEP 3")]), _c('txt-primary', {
    staticClass: "grey--text text--lighten-1 mt-2px",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("템플릿 등록")]), _c('div', {
    staticClass: "v-stepper__arrow"
  }, [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "right",
      "color": "grey--text text--lighten-3"
    }
  })], 1)], 1), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.value >= 4,
      "step": "4"
    }
  }, [_c('div', {
    staticClass: "v-stepper__icon"
  }, [_c('icon-order-completed')], 1), _c('tit-primary', {
    staticClass: "grey--text",
    attrs: {
      "titSize": "tit--xs"
    }
  }, [_vm._v("STEP 4")]), _c('txt-primary', {
    staticClass: "grey--text text--lighten-1 mt-2px",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._v("주문완료")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }