<template>
    <client-page>
        <page-section-primary class="page-section--first page-section--last" ref="stepperItems" >
            <v-stepper v-if="ready" v-model="step" alt-labels non-linear>
                <!-- S: Stepper Step -->
                <stepper-header v-model="step" v-bind="{ card_primary }"/>
                <!-- E: Stepper Step -->

                <v-stepper-items>
                    <!-- S: 주문정보 -->
                    <v-stepper-content step="1">
                        <buyingOrderStep1 v-model="order" v-bind="{ attrs_input, btn_primary, attrs_select_common, attrs_controls_common }" v-on="{ go }" ref="step1"/>
                    </v-stepper-content>
                    <!-- E: 주문정보 -->
                    <!-- S: 수신자등록 -->
                    <v-stepper-content step="2">
                        <buyingOrderStep2 v-model="order" v-bind="{ attrs_input, btn_primary, btn_primary2, table_primary, btn_secondary, excelItems, display, quantity  }" v-on="{ go, prev }" ref="step2"/>
                    </v-stepper-content>
                    <!-- E: 수신자등록 -->
                    <!-- S: 템플릿등록 -->
                    <v-stepper-content step="3">
                        <buyingOrderStep3 v-model="order" v-bind="{ attrs_input, btn_primary, card_primary, btn_primary3, btn_secondary, card_secondary, attrs_controls_common, templates, page, pageCount, company, carts }" v-on="{ go, prev, getTemplate, setTemplate }" ref="step3"/>
                    </v-stepper-content>
                    <!-- E: 템플릿등록 -->
                    <!-- S: 주문완료 -->
                    <v-stepper-content step="4">
                        <buyingOrderStep4 v-model="carts[0]" v-bind="{ card_primary, btn_primary, totalAmount, table_primary, excelItems: displayExcelItems }" v-on="{ pay }"/>
                    </v-stepper-content>
                    <!-- E: 주문완료 -->
                </v-stepper-items>
            </v-stepper>
        </page-section-primary>
    </client-page>
</template>

<script>
import api from "@/api"
import { attrs_input, attrs_controls_common, attrs_select_common, btn_primary, btn_primary2, btn_primary3, btn_secondary, btn_tertiary, card_primary, card_secondary, table_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import stepperHeader from '@/components/client/sub/buying/stepper-header.vue';
import buyingOrderStep1 from "@/components/client/sub/buying/buying-order-step1.vue";
import buyingOrderStep2 from "@/components/client/sub/buying/buying-order-step2.vue";
import buyingOrderStep3 from "@/components/client/sub/buying/buying-order-step3.vue";
import buyingOrderStep4 from "@/components/client/sub/buying/buying-order-step4.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";


export default {
    components: {
        ClientPage,
        stepperHeader,
        buyingOrderStep1,
        buyingOrderStep2,
        buyingOrderStep3,
        buyingOrderStep4,
        PageSectionPrimary,
    },

    data() {
        return {
            attrs_input,
            attrs_controls_common,
            attrs_select_common,
            btn_primary,
            btn_primary2,
            btn_primary3,
            btn_secondary,
            btn_tertiary,
            card_primary,
            card_secondary,
            table_primary,

            step: 1,
            ready: false,

            limit: 10,
            page: 1,
            pageCount: 1,

            shows: false,
            sendingPurpose: null,
            quantity: 0,

            carts: [],

            order: {
                _id: null,
                pickups: [],
                orderName: null,

                buyer: {
                    _id: null,
                    name: null,
                    username: null,

                    phone: null,
                    phone1: "010",
                    phone2: "",
                    phone3: "",

                    email: null,

                    birthday: null
                },

                deliveryMethod : "KAKAO",
                sendingMode: "IMMEDIATE",
                sendingPurpose: "사은품",
            },

            excelItems: [],
            templates:[],
            template: null,
            company: null
        };
    },
    async mounted() {
        if (!this.accessToken) {
            alert("로그인 후 이용할 수 있습니다.")
            this.$router.push("/login");
        }
        await this.init();
    },
    methods: {
        async init() {
            this.ready = false;
            // 상품페이지 & 장바구니에서 픽업된 상품 로드
            if(!this.pickup.length) this.$router.push("/buying");

            let carts = this.pickup;
            this.carts = carts;
            this.order.pickups = carts;
            this.order.orderName = this.carts[0].productName;
            this.quantity = Number(this.carts[0].quantity);
            
            for (let i = 0; i < this.quantity; i++) {
                this.excelItems.push({ name: null, phone: null, quantity: null })
            }
            await this.getTemplate();
            await this.getUser();
            this.ready = true;
        },
        async getUser() {
            let { company } = (await api.v1.me.get()).user
            this.company = company;
        },
        async getTemplate(page) {
            if (page) this.page = page
            let { templates, summary } = await api.v1.me.templates.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                }
            })
            this.templates = templates
            this.pageCount = Math.ceil(summary.totalCount / this.limit) || 1;
        },
        setTemplate(template) {
            this.template = template;
        },
        validate() {
            try {
                this.$refs.step1.validate();
                this.$refs.step2.validate();
                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
        async pay() {
            if (this.validate()) {
                if (confirm("최종 발송하기 진행하시겠습니까?")) {
                let { order } = await api.v1.gshop.orders.post({ ...this.order, excelItems: this.displayExcelItems, template: this.template })
                alert("나누리상품권이 발송되었습니다")
                this.$router.push("/mypage/orders")
                }   
            }
        },
        go(step) {
            this.step = step;
        },
        prev(step) {
            this.step = step;
        },
        close() {
            this.shows = false;
        },

        scrollTop() {
            let el = this.$refs.stepperItems;
            if (el) {
                el.$el.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            }
        }
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        pickup(){
            return this.$store.state.pickup || [];
        }, 
        display() {
            return Number(this.excelItems.filter((el) => el.name && el.phone && el.quantity).reduce((sum, el) => Number(sum) + Number(el.quantity), 0))
        },
        totalAmount() {
            return this.carts[0].faceamt * this.carts[0].quantity;
        },
        displayExcelItems() {
			return this.excelItems.filter(el => el.phone && el.name && el.quantity)
		},
    },
    watch: {
        step() {
            this.scrollTop();
        },
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-stepper {
        --stepper-icon-size: 80px;
        border-radius: 0;
        box-shadow: none;
        &__header {
            align-items: flex-start;
            justify-content: center;
            padding: 10px;
            box-shadow: none;
        }
        &__step {
            padding: 0;
            flex-basis: auto;
            &__step {
                display: none;
            }
            &--complete {
                .v-stepper__icon {
                    color: var(--v-primary-base);
                    border-color: var(--v-primary-base);
                    background-color: #fff;
                }
                .tit,
                .v-stepper__arrow .icon-svg {
                    color: var(--v-primary-base) !important;
                }
                .txt {
                    color: var(--v-grey-darken4) !important;
                }
            }
            &--editable:hover {
                background: transparent !important;
            }
        }
        &__label {
            position: relative;
            display: flex !important;
            text-align: center;
            align-items: center;
            padding: 0 26px;
            margin: 10px 0;
            text-shadow: none !important;
        }
        &__icon,
        &__arrow {
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--stepper-icon-size);
            height: var(--stepper-icon-size);
            border-radius: 100%;
            margin-bottom: 10px;
            color: var(--v-grey-lighten2);
            border: 1px solid var(--v-grey-lighten5);
            background-color: var(--v-grey-lighten5);
        }
        &__arrow {
            position: absolute;
            top: calc(var(--stepper-icon-size) / 2);
            right: 0;
            transform: translate(50%, -50%);
            display: flex;
            .icon--direction-right {
                width: 10px;
            }
        }
        &__content {
            padding: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-stepper {
            --stepper-icon-size: 100px;
            &__header {
                padding: 20px;
            }
            &__icon {
                margin-bottom: 20px;
            }
            &__arrow {
                .icon--direction-right {
                    width: 16px;
                }
            }
            &__label {
                padding: 0 36px;
            }
        }
    }
}
@media (min-width: 1024px) {
    ::v-deep {
        .v-stepper {
            &__header {
                padding: 30px;
            }
            &__label {
                padding: 0 46px;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>
