var render = function render(){
  var _vm$faqs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": _vm.search
    }
  }), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.faqs, function (faq) {
    return _c('v-expansion-panel', {
      key: faq
    }, [_c('v-expansion-panel-header', {
      staticClass: "pa-12px px-md-16px border-b"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('div', {
      staticClass: "min-w-70px"
    }, [_c('v-chip', {
      attrs: {
        "x-small": "",
        "outlined": ""
      }
    }, [_vm._v("회원문의")])], 1)]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('txt-primary', {
      staticClass: "txt--dark font-weight-bold",
      attrs: {
        "txtSize": "txt--lg"
      }
    }, [_vm._v("Q")])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('txt-primary', {
      staticClass: "txt--dark font-weight-medium",
      attrs: {
        "txtSize": "txt--sm"
      }
    }, [_vm._v(_vm._s(faq.subject))])], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel-content', {
      staticClass: "border-b"
    }, [_c('div', {
      staticClass: "grey lighten-5 pa-12px px-md-16px pl-lg-100px pr-lg-48px"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('txt-primary', {
      staticClass: "font-weight-bold grey--text text--lighten-1",
      attrs: {
        "txtSize": "txt--lg"
      }
    }, [_vm._v("A")])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('txt-primary', {
      attrs: {
        "txtSize": "txt--sm"
      }
    }, [_c('span', {
      staticClass: "break-words",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])], 1)], 1)], 1)])], 1);
  }), 1), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$faqs = _vm.faqs) !== null && _vm$faqs !== void 0 && _vm$faqs.length),
      expression: "!faqs?.length"
    }],
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm text-center py-20px py-md-30px"
  }, [_vm._v("등록된 질문이 없습니다")]), _c('v-divider')], 1)], 1), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }