var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-simple-table', {
    staticClass: "v-data-table--line table-fixed"
  }, [_c('tbody', [_c('tr', [_c('th', [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "성함을 입력해주세요."
    },
    model: {
      value: _vm.question.name,
      callback: function ($$v) {
        _vm.$set(_vm.question, "name", $$v);
      },
      expression: "question.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1)]), _c('tr', [_c('th', [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "제목을 입력해주세요."
    },
    model: {
      value: _vm.question.subject,
      callback: function ($$v) {
        _vm.$set(_vm.question, "subject", $$v);
      },
      expression: "question.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1)]), _c('tr', [_c('td', [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.typesItems
    },
    model: {
      value: _vm.question.type,
      callback: function ($$v) {
        _vm.$set(_vm.question, "type", $$v);
      },
      expression: "question.type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)]), _c('tr', [_c('td', [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "이메일을 입력하세요."
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _vm._v(" @ "), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-combobox', _vm._b({
    attrs: {
      "items": _vm.emailItems,
      "placeholder": "직접입력"
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  }, 'v-combobox', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1)]), _c('tr', [_c('td', [_c('v-textarea', _vm._b({
    attrs: {
      "placeholder": "내용을 입력해주세요."
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)])])]), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }