var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section-primary', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xl"
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "auto",
            "order-md": "2"
          }
        }, [_c('v-card', _vm._b({
          attrs: {
            "min-width": "282px"
          }
        }, 'v-card', Object.assign({}, _vm.card_secondary), false), [_c('div', {
          staticClass: "pa-16px pa-md-24px"
        }, [_c('tit-wrap-primary', {
          attrs: {
            "titWrapSize": "pb-12px pb-md-16px",
            "titWrapAlign": "text-left",
            "titSize": "tit--xs"
          }
        }, [_vm._v("미리보기")]), _c('v-card', _vm._b({}, 'v-card', Object.assign({}, _vm.card_primary), false), [_c('v-textarea', _vm._b({
          attrs: {
            "value": _vm.preview,
            "readonly": "",
            "no-resize": "",
            "rows": "9"
          }
        }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)], 1)]), _c('div', {
          staticClass: "mt-8px mt-md-12px text-right"
        }, [_c('v-chip', {
          attrs: {
            "small": "",
            "color": "primary lighten-5"
          }
        }, [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("단문 " + _vm._s(_vm.preview.length + 590) + "/1000")])])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "",
            "order-md": "1"
          }
        }, [_c('v-form', [_c('tit-form-section', {
          attrs: {
            "items": _vm.templateInput
          }
        }, [_c('template', {
          slot: "회사명"
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "value": _vm.company.name,
            "readonly": "",
            "placeholder": "회사명을 입력해주세요"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('template', {
          slot: "템플릿명"
        }, [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": ""
          }
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "placeholder": "템플릿을 입력해주세요",
            "disabled": _vm.disabled
          },
          model: {
            value: _vm.template.name,
            callback: function ($$v) {
              _vm.$set(_vm.template, "name", $$v);
            },
            expression: "template.name"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "auto"
          }
        }, [_c('u-dialog', {
          attrs: {
            "title": "템플릿 목록",
            "cardInnerPadding": "pt-0 px-0 pb-12px pb-md-20px pb-lg-24px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [!_vm.template._id ? _c('v-btn', _vm._g(_vm._b({
                staticClass: "v-btn--input-comfortable",
                attrs: {
                  "x-large": "",
                  "block": "",
                  "color": "grey"
                }
              }, 'v-btn', Object.assign({}, _vm.btn_primary, {
                attrs
              }), false), on), [_vm._v("템플릿 불러오기")]) : _c('v-btn', _vm._b({
                staticClass: "v-btn--input-comfortable",
                attrs: {
                  "x-large": "",
                  "block": "",
                  "color": "grey"
                },
                on: {
                  "click": _vm.initTemplate
                }
              }, 'v-btn', Object.assign({}, _vm.btn_primary, {
                attrs
              }), false), [_vm._v("템플릿 초기화")])];
            }
          }]),
          model: {
            value: _vm.shows,
            callback: function ($$v) {
              _vm.shows = $$v;
            },
            expression: "shows"
          }
        }, [_vm._l(_vm.templates, function (template) {
          return _c('div', {
            key: template._id,
            staticClass: "pa-12px px-md-16px border-b border-light"
          }, [_c('v-row', {
            staticClass: "row--xs",
            attrs: {
              "align": "center",
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "12",
              "md": ""
            }
          }, [_c('txt-primary', {
            attrs: {
              "txtSize": "txt--sm"
            }
          }, [_vm._v(_vm._s(template === null || template === void 0 ? void 0 : template.name))])], 1), _c('v-col', {
            attrs: {
              "cols": "12",
              "md": "auto"
            }
          }, [_c('v-row', {
            staticClass: "row--xxs",
            attrs: {
              "justify": "end"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-btn', _vm._b({
            staticClass: "v-size--xx-small",
            on: {
              "click": function ($event) {
                return _vm.deleteTemplate(template);
              }
            }
          }, 'v-btn', Object.assign({}, _vm.btn_primary3), false), [_vm._v("삭제하기")])], 1), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-btn', _vm._b({
            staticClass: "v-size--xx-small",
            on: {
              "click": function ($event) {
                return _vm.selectTemplate(template);
              }
            }
          }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("선택하기")])], 1)], 1)], 1)], 1)], 1);
        }), _c('div', {
          staticClass: "pagination-wrap pagination-wrap--sm"
        }, [_c('v-pagination', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": _vm.handlePageChange
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1)], 2)], 1)], 1)], 1), _c('template', {
          slot: "제목"
        }, [_c('v-text-field', _vm._b({
          attrs: {
            "placeholder": "제목을 입력해주세요",
            "disabled": _vm.disabled
          },
          model: {
            value: _vm.template.subject,
            callback: function ($$v) {
              _vm.$set(_vm.template, "subject", $$v);
            },
            expression: "template.subject"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('template', {
          slot: "컨텐츠"
        }, [_c('div', [_c('v-textarea', _vm._b({
          attrs: {
            "placeholder": "메시지를 입력해주세요. (ex. 인사말, 이벤트 내용)",
            "maxlength": 282,
            "counter": 282,
            "no-resize": "",
            "rows": "9",
            "disabled": _vm.disabled
          },
          on: {
            "input": _vm.validateContent
          },
          model: {
            value: _vm.template.content,
            callback: function ($$v) {
              _vm.$set(_vm.template, "content", $$v);
            },
            expression: "template.content"
          }
        }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)]), _c('template', {
          slot: "고객센터"
        }, [_c('div', [_c('v-text-field', _vm._b({
          attrs: {
            "placeholder": "고객센터",
            "maxlength": 14,
            "counter": 14,
            "disabled": _vm.disabled
          },
          model: {
            value: _vm.template.center,
            callback: function ($$v) {
              _vm.$set(_vm.template, "center", $$v);
            },
            expression: "template.center"
          }
        }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('div', {
          staticClass: "pt-8px pt-md-12px"
        }, [_c('div', [_vm._v("*지정된 특수문자만 사용이 가능합니다. 이외의 특수문자 입력 시 오류가 발생할 수 있습니다. "), _c('v-icon', [_vm._v("mdi-help-circle-outline")])], 1), _c('div', [_vm._v("*내용에 {이름}을 입력해주시면 각 문자 메시지에 자동으로 이름이 입력되어 발송됩니다.")])]), _c('div', {
          staticClass: "pt-8px pt-md-12px"
        }, [_c('v-textarea', _vm._b({
          attrs: {
            "value": _vm.preview,
            "readonly": "",
            "no-resize": "",
            "rows": "9"
          }
        }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1), _c('div', {
          staticClass: "pt-16px pt-md-24px"
        }, [_c('v-checkbox', _vm._b({
          attrs: {
            "label": "템플릿으로 저장하기",
            "disabled": _vm.disabled
          },
          model: {
            value: _vm.template.isSaved,
            callback: function ($$v) {
              _vm.$set(_vm.template, "isSaved", $$v);
            },
            expression: "template.isSaved"
          }
        }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1)])], 2)], 1)], 1)], 1), _c('div', {
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('prev', 2);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("이전으로 이동")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": _vm.goStep
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("다음으로 이동")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }